import React from "react";
import { GlassSelector, Rail as LlamaRail } from "@ixd-group/llama-components";

import { grid, rail } from "../../../../constants/dimensions";
import type { default as RailType } from "../../../../types/rail.type";
import Tile from "../Tile";
import { Container, TileContainer } from "./index.styles";

export type RailProps = RailType & {
  index: number;
  isFocused: boolean;
  showTitle: boolean;
}

const Rail: React.FC<RailProps> = ({ 
  index, 
  isFocused, 
  items, 
  showGradient,
  showTitle, 
  template, 
  title 
}) => {
  const renderItems = (isFocused = false) => items.map((item, index) => (
    <TileContainer isFocused={isFocused} key={index}>
      <Tile 
        isFocused={isFocused} 
        index={showGradient ? index : undefined} 
        tile={item} 
      />
    </TileContainer>
  ));

  return (
    <Container 
      isFocused={isFocused}
      showTitle={showTitle}
    >
      <LlamaRail 
        focused={isFocused}
        showTitle={showTitle}
        title={title}
        titleOffset={rail.titleOffset(isFocused, template)}
      >
        <GlassSelector
          focused={isFocused}
          focusedExtraWidth={2 * grid.column.gap}
          focusedHeight={rail.focusedHeight(template)}
          focusedItems={() => renderItems(true)}
          height={rail.height(template) ?? 300}
          index={index}
          items={() => renderItems()}
          width={rail.innerWidth}
          withMask={items.some(({ type }) => ["category", "nowAndNext", "title"].includes(type))}
        />
      </LlamaRail>
    </Container>
  );
}

export default Rail;
