import React from "react";
import { useButtons } from "@ixd-group/react-utils";
import { ButtonHandler } from "@ixd-group/common-services";

import { screen } from "../../constants/dimensions";
import styled from "@emotion/styled";
import ReactPlayer from "react-player";

const Layer = styled.div`
  width: 1920px;
  height: 1080px;
  position: absolute;
`;

export type VideoPageProps = {
  buttonHandler: ButtonHandler;
  onBack?: () => void;
  video: string;
  image: string;
};

const VideoPage: React.FC<VideoPageProps> = ({
  buttonHandler,
  onBack,
  image,
  video,
}) => {
  useButtons(["browse"], buttonHandler, true, {
    BACK: onBack,
  });

  return (
    <div style={{ position: "relative" }}>
      <Layer>
        <img
          alt="mock"
          src={image}
          style={{
            height: screen.height,
            width: screen.width,
          }}
        />
      </Layer>
      <Layer>
        <ReactPlayer
          width={screen.width}
          height={screen.height}
          url={video}
          loop={true}
          playing={true}
          // muted={true}
          volume={1}
          controls={false}
        />
      </Layer>
      {/* Layer prevents interaction with video player */}
      <Layer />
    </div>
  );
};

export default VideoPage;
