import styled from "@emotion/styled";

import { screen } from "../../constants/dimensions";

export const Container = styled.div<{ scale: number, style: React.CSSProperties }>`
    background-image:"url('assets/images/backgrounds/llama-wallpaper.jpg')";
    height: ${screen.height}px;
    overflow: hidden;
    position: relative;
    transform: scale(${p => p.scale});
    transform-origin: top left;
    width: ${screen.width}px;
    transition:all 0.66;
`;