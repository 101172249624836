import { useEffect, useMemo } from "react";
import { useAtom, useButtons, useSubscribe } from "@ixd-group/react-utils";
import { ButtonHandler } from "@ixd-group/common-services";

import type Action from "../../types/action.type";
import type HistoryItem from "../../types/history-item.type";
import type Tile from "../../types/tile.type";
import { createServices, Services } from "./services";
import { createStores } from "./stores";
import { RailsPageProps } from "./";

export const useRailsPageLogic = (
    buttonHandler: ButtonHandler | undefined,
    isFocused: boolean,
    onBack: (() => void) | undefined,
    onSelect: ((action?: Action, indices?: HistoryItem["indices"]) => void) | undefined,
    onTileChange: ((tile?: Tile) => void) | undefined,
    page: RailsPageProps["page"],
    pubSub: Services["pubSub"]
) => {
    const isFocused$ = useAtom(isFocused);

    const services = useMemo(() => {
        return createServices(buttonHandler, pubSub);
    }, [buttonHandler, pubSub]);
    const stores = useMemo(() => {
        return createStores(isFocused$);
    }, [isFocused$]);

    useEffect(() => {
        stores.railsStore.set(page.rails, page.indices);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useButtons(["browse"], services.buttonHandler, isFocused, {
        BACK: onBack,
        HOME: () => {
            if (page.id === "home") {
                stores.railsStore.setItemIndex(0, 0);
                stores.railsStore.setRailIndex(0);
            } else {
                return "continue";
            }
        },
        SELECT: () => {
            const item = stores.railsStore.focusedItem$.get();
            const railIndex = stores.railsStore.railIndex$.get();
            onSelect?.(item.action, {
                itemIndices: stores.railsStore.itemIndices(),
                railIndex
            });
        }
    });

    useSubscribe(isFocused$, (isFocused) => {
        if (isFocused) {
            onTileChange?.(stores.railsStore.focusedItem$.get());
        }
    });

    useSubscribe(stores.railsStore.focusedItem$, (tile) => {
        if (isFocused) {
            onTileChange?.(tile);
        }
    });

    return { services, stores };
};
