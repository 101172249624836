import type { FanPageData } from "../../../types/page.type";
import { mediaData } from "./media";

const page: FanPageData = {
  type: "fan",
  id: "greysAnatomyFanPage",
  showpageImageUrl:
    "https://images.metadata.sky.com/pd-image/4af72b09-5e0e-48a7-9db8-5c5abf951e7e/16-9/1920",
  media: mediaData.Greys,
  hero: {
    title: "Grey's Anatomy",
    mode: "standard",
    synopsis:
      "Medical residents try to cope with the demands of life both inside and outside the hectic hospital.",
    logoUrl:
      "http://myriad.merlin.xfinity.eu/select/logo?entityId=7872603476396289105&width=368&height=207&rule=Sky_Logo_Padded&trim=false&partnerIdentifier=sky-uk",
    metadata: "2021 ",
    imageUrl:
      "http://myriad.merlin.xfinity.eu/select/image?entityId=7305834959116634112&companyId=7872603476396289105&width=1920&height=1080&rule=Sky_Background&partnerIdentifier=sky-uk",
    titleImageUrl:
      "http://cdn.skyq.sky.com/qms/media/gbr/live/199ee2662bf4633b547e6c6ce8a183b4/herotitle.png",
  },
};

export default page;
