import type Rail from "../../../types/rail.type";

const rail: Rail = {
    template: "tile-landscape",
    title: "Catch up TV shows",
    items: [
        {
            type: "title",
            title: "Catch up TV shows",
            hero: {
                mode: "standard",
                title: "Catch up TV shows",
                imageUrl: "https://images.metadata.sky.com/pd-image/16842538-afb8-4731-a07f-13341e521e58/background/{width}",
            },
            actionText: "View all",
        },
        {
            type: "landscape",
            title: "Gentleman Jack",
            imageUrl: "assets/images/content/gentleman_jack/tile.png",
            providerImageUrl:
                "assets/images/provider/bbc_iplayer.png",
            hero: {
                mode: "standard",
                imageUrl: "https://images.metadata.sky.com/pd-image/16842538-afb8-4731-a07f-13341e521e58/background/{width}",
                logoUrl: "assets/images/provider/bbc_iplayer.png",
                title: "Gentleman Jack",
                synopsis: "Halifax, 1832. Rejected by aristocratic Vere Hobart, Anne Lister returns to her shabby ancestral home, Shibden Hall, determined to restore its fortunes and find herself a wife."
            }
        },
        {
            type: "landscape",
            title: "Grace",
            imageUrl: "assets/images/content/grace/tile.png",
            providerImageUrl:
                "assets/images/provider/itv.png",
            hero: {
                mode: "standard",
                imageUrl: "https://images.metadata.sky.com/pd-image/631163b0-d920-4b57-b160-2f4c6d8f037c/background/{width}",
                logoUrl: "assets/images/provider/itv.png",
                title: "Grace",
                synopsis:
                    "Written by the critically-acclaimed screenwriter Russell Lewis, this adaptation of the bestseller by Peter James stars John Simm and follows the Brighton-based Detective Roy Grace, who has hit rock bottom in his life."
            }
        },
        {
            type: "landscape",
            title: "Rick and Morty",
            imageUrl: "assets/images/content/rick_and_morty/tile.png",
            providerImageUrl:
                "assets/images/provider/channel_4.png",
            hero: {
                mode: "standard",
                imageUrl: "assets/images/content/rick_and_morty/hero.jpg",
                logoUrl: "assets/images/provider/channel_4.png",
                title: "Rick and Morty",
                synopsis:
                    "After having been missing for nearly 20 years, Rick Sanchez suddenly arrives at daughter Beth's doorstep to move in with her and her family. Although Beth welcomes Rick into her home, her husband, Jerry, isn't as happy about the family reunion."
            }
        },
        {
            type: "landscape",
            title: "Posh Pawnbrokers",
            imageUrl: "assets/images/content/posh_pawnbrokers/tile.png",
            providerImageUrl:
                "assets/images/provider/channel_4.png",
            hero: {
                mode: "standard",
                imageUrl: "assets/images/content/posh_pawnbrokers/hero.jpg",
                logoUrl: "assets/images/provider/channel_4.png",
                title: "Posh Pawnbrokers",
                synopsis:
                    "At Nikolas Patrick Pawnbrokers in Kent, brothers Charlie and Patrick are looking at a staggering collection of boxing memorabilia owned by former boxing promoter Kellie Maloney, and an unusual electric unicycle."
            }
        }
    ]
};

export default rail;