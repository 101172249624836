import type Rail from "../types/rail.type";
import type Tile from "../types/tile.type";

const widthFromColumns = (columns: number) =>
    grid.column.width * columns + grid.column.gap * (columns - 1);

const tileBorderRadius = 6;

export const grid = {
    column: {
        width: 49,
        gap: 24,
    },
    get startX() {
        return grid.safeArea.width + 16;
    },
    safeArea: {
        width: 80,
        height: 50,
    },
};

export const railsPage: {
    yLines: {
        default: number;
    } & Partial<{ [key in Rail["template"]]: number }>;
} = {
    yLines: {
        "midroll": 0,
        "tile-promo": 421,
        "tile-category": 600,
        default: 575
    }
};

export const rail = {
    height: (template: Rail["template"]) => {
        switch (template) {
            case "midroll":
                return 704;
            case "tile-landscape":
                return tiles.landscape.height;
            case "tile-promo":
                return tiles.promo.height;
            case "tile-cover":
                return tiles.cover.height;
            case "tile-now-next":
                return tiles.nowAndNext.height;
            case "tile-category":
                return tiles.category.height;
            case "tile-square":
                return tiles.square.height;
            case "tile-switcher":
                return tiles.switcher.height;
        }
    },
    extraFocusedHeight: (template: Rail["template"]) => {
        switch (template) {
            case "tile-landscape":
            case "tile-promo":
                return 38;
            case "tile-cover":
            case "tile-now-next":
            case "tile-square":
                return 58;
            case "tile-category":
                return 18;
            case "tile-switcher":
                return tiles.switcher.extraFocusedHeight;
        }
    },
    focusedHeight: function (template: Rail["template"]) {
        return this.height(template)! + this.extraFocusedHeight(template);
    },
    innerWidth: widthFromColumns(24),
    titleOffset: function (
        allowForFocusState: boolean,
        template: Rail["template"]
    ) {
        return -48 - (allowForFocusState ? this.extraFocusedHeight(template) / 2 : 0);
    },
    marginTop: (showTitle: boolean) => (showTitle ? 84 : 80)
};

export const screen = {
    height: 1080,
    width: 1920
};

export const tiles: {
    [key in Tile["type"]]: {
        width: number;
        height?: number;
        borderRadius: number;
        [attr: string]: any;
    };
} = {
    button: {
        width: 370,
        height: 96,
        borderRadius: tileBorderRadius
    },
    category: {
        width: widthFromColumns(6),
        height: 154,
        borderRadius: tileBorderRadius,
    },
    cover: {
        width: widthFromColumns(4),
        get height() {
            return this.width / (3 / 4);
        },
        borderRadius: tileBorderRadius,
    },
    landscape: {
        width: widthFromColumns(6),
        get height() {
            return this.width / (16 / 9);
        },
        borderRadius: tileBorderRadius,
    },
    nowAndNext: {
        width: widthFromColumns(6),
        height: 357,
        borderRadius: tileBorderRadius,
        nowHeight: 234,
        get nextHeight() {
            return this.height! - this.nowHeight;
        },
    },
    promo: {
        width: widthFromColumns(12),
        get height() {
            return this.width / (16 / 9);
        },
        borderRadius: tileBorderRadius,
    },
    square: {
        width: widthFromColumns(4),
        get height() {
            return this.width;
        },
        borderRadius: tileBorderRadius,
    },
    switcher: {
        width: widthFromColumns(8),
        get outerWidth() {
            return this.width + grid.column.gap;
        },
        get height() {
            return this.width / (16 / 9);
        },
        borderRadius: tileBorderRadius,
        get focusedHeight() {
            const scale = (this.width + 2 * grid.column.gap) / this.width;
            return scale * this.height!;
        },
        get extraFocusedHeight() {
            return this.focusedHeight - this.height!;
        }
    },
    title: {
        width: widthFromColumns(4),
        borderRadius: tileBorderRadius,
    }
};
