import React, { useRef } from "react";
import { KeepPrevious } from "@ixd-group/llama-components";
import {
  useRxState,
  useServices,
  useStores,
  useSubscribe,
} from "@ixd-group/react-utils";

import type Action from "../../../../../types/action.type";
import type HistoryItem from "../../../../../types/history-item.type";
import { getInitialFocusArea } from "../../../../../utilities";
import { getPage } from "../../../../../data/pages";
import { Services } from "../../../services";
import { Stores } from "../../../stores";
import Page from "../";

const PageController: React.FC = () => {
  const { buttonHandler, pubSub } = useServices<Services>();
  const {
    currentPage$,
    focusArea$,
    heroStore,
    historyStore,
    homeId$,
    isSubscribed$,
    isSubscribedKids$,
    isSubscribedSports$,
  } = useStores<Stores>();

  const [isFocused, page, isSubscribed] = useRxState([
    focusArea$.map((area) => area === "browse"),
    currentPage$,
    isSubscribed$,
  ]);

  const pageRef = useRef<HTMLDivElement>(null),
    prevPageRef = useRef<HTMLDivElement>(null);

  const animate = (forward = true) => {
    pageRef.current?.animate(
      [
        {
          opacity: 0,
          transform: `scale(${forward ? 0.9 : 1.1})`,
        },
        { opacity: 1, transform: "scale(1)" },
      ],
      {
        duration: 1200,
        fill: "forwards",
        easing: "cubic-bezier(0.33, 1, 0.68, 1)",
      }
    );
    prevPageRef.current?.animate(
      [
        { opacity: 1, transform: "scale(1)" },
        {
          opacity: 0,
          transform: `scale(${forward ? 1.1 : 0.9})`,
        },
      ],
      {
        duration: 800,
        fill: "forwards",
        easing: "cubic-bezier(0.33, 1, 0.68, 1)",
      }
    );
  };

  useSubscribe(currentPage$, (page) => {
    animate(false);
    if (page.type === "product") {
      heroStore.set(page.hero);
    }
  });

  const handleBack = () => {
    return "continue";
  };

  const handleSelect = (action?: Action, indices?: HistoryItem["indices"]) => {
    if (action && action.type === "GO_TO_PAGE") {
      historyStore.push({ ...page, indices });
      try {
        const page = getPage(action.pageId, action.pageType);
        focusArea$.set(getInitialFocusArea(page.type));
        currentPage$.set(page);
        animate();
        if (action.pageId === "home") {
          historyStore.clear();
          homeId$.set(action.pageId);
        }
      } catch {
        historyStore.pop();
      }
    }
  };

  const handleSubscribe = () => {
    if (page.type === "show") {
      switch (page.needsSubscription) {
        case "kids":
          isSubscribedKids$.set(true);
          break;
        case "sports":
          isSubscribedSports$.set(true);
          break;
      }
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <KeepPrevious
        duration={1.2}
        keyBy={page.id}
        props={{ isFocused, page }}
        render={(props, key, isPrevious) => (
          <Page
            {...props}
            buttonHandler={buttonHandler}
            key={key}
            onBack={handleBack}
            onSelect={handleSelect}
            onTileChange={(tile) => {
              if (props.page.id === page.id) {
                heroStore.set(tile?.hero);
              }
            }}
            pubSub={pubSub}
            ref={isPrevious ? prevPageRef : pageRef}
            isSubscribed={isSubscribed}
            onSubscribe={handleSubscribe}
          />
        )}
      />
    </div>
  );
};

export default PageController;
