import {FanMediaData} from "../../../types/fan.types";

export const DuneExtras:FanMediaData[] = [
    {
        id: "duneExtra01",
        views: "",
        date: "",
        title: "Take a selfie with Timothée Chalamet",
        account: "",
        tick: false,
        video: "",
        image: "https://mindlifetv.com/wp-content/uploads/2021/11/1635726965_425_Duna-10-images-that-prove-that-Timothee-Chalamet-and-Zendaya.png",
    },
    {
        id: "duneExtra02",
        views: "",
        date: "",
        title: "Zendaya wants to wish you a HAPPY BIRTHDAY!",
        account: "",
        tick: false,
        video: "",
        image: "https://i.ytimg.com/vi/P1EE2_UgvKc/maxresdefault.jpg",
    },
    {
        id: "duneExtra03",
        views: "",
        date: "",
        title: "Exclusive Superfan Content!",
        account: "",
        tick: false,
        video: "",
        image: "https://preview.redd.it/lg8t5fad28x71.jpg?width=960&crop=smart&auto=webp&s=668da65f9e9586b956f50ca6d510cc3b5934ab6c",
    }
]