import React from "react";
import { VerticalScroller } from "@ixd-group/llama-components";

import type Rail from "../../../../types/rail.type";
import { transitions } from "../../../../constants/transitions";
import { calculatePageOffsetY } from "../../../../utilities";
import RailController from "../Rail/RailController";

export type RailsProps = {
  index: number;
  indices: { [index: number]: number }
  rails: Rail[];
  transition?: typeof transitions["rail"];
  yLine: number;
}

const Rails: React.FC<RailsProps> = ({ 
  index, 
  indices, 
  rails,
  transition = transitions.rail,
  yLine 
}) => (
  <div
    style={{
      transform: `translateY(${yLine}px)`,
      transition: `transform ${transition.duration}s ${transition.timingFunction}`
    }}
  >
    <VerticalScroller
      calculateOffset={(index) =>
        calculatePageOffsetY(rails, index, indices)}
      index={index}
      transition={transition}
    >
      {rails.map((rail, index) => (
        <RailController
          index={index}
          key={index}
          rail={rail}
        />
      ))}
    </VerticalScroller>
  </div>
);

export default Rails;
