import type { FanPageData } from "../../../types/page.type";
import { mediaData } from "./media";

const page: FanPageData = {
  type: "fan",
  id: "bayernFanPage",
  showpageImageUrl: "assets/images/fanPages/allianz_arena6.jpg",
  media: mediaData.Bayern,
  hero: {
    mode: "standard",
    logoUrl:
      "http://myriad.merlin.xfinity.eu/select/logo?entityId=6737930311996437105&width=368&height=207&rule=Sky_Logo_Padded&trim=false&partnerIdentifier=sky-uk",
    title: "Bayern Munich v Stuttgart",
    synopsis:
      "Stuttgart pay a visit to the Allianz Arena, home of the German champions Bayern Munich. The visitors face an uphill battle to cling onto their Bundesliga status.",
    metadata: "2022 ",
    imageUrl:
      "http://myriad.merlin.xfinity.eu/select/image?entityId=5551312309082791112&companyId=6737930311996437105&width=1920&height=1080&rule=Sky_Background&partnerIdentifier=sky-uk",
    titleImageUrl: "",
  },
};

export default page;
