import styled from "@emotion/styled";

import { screen } from "../../../../constants/dimensions";

export const Wrapper = styled.div`
    left: 0;
    position: absolute;
    top: 0;
    background-image:"url('./assets/images/backgrounds/themebackgroundnologo.jpeg')";
    transform-origin: ${screen.width / 2}px ${screen.height / 2}px;
    transition: all 0.6;
`;