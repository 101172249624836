import type { ShowPage } from "../../../../types/page.type";

const page: ShowPage = {
    type: "show",
    id: "dreamflight",
    needsSubscription: "kids",
    showpageImageUrl: "assets/images/mocks/dreamflight.png",
    upsellImageUrl: "assets/images/mocks/kids-upsell.png",
    confirmationImageUrl: "assets/images/mocks/confirm-sky-kids.png"
};

export default page;