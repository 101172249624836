import type Rail from "../../../types/rail.type";

const rail: Rail = {
    template: "tile-landscape",
    title: "Playlist",
    items: [
        {
            type: "title",
            title: "Playlist",
            hero: {
                mode: "standard",
                title: "Playlist",
                synopsis: "Your playlist of shows and movies.",
                imageUrl: "https://images.metadata.sky.com/pd-image/02d6f8bd-1298-4cb6-8c8b-f83c33a43f6e/background/{width}",
            },
            actionText: "View all",
        },
        {
            type: "landscape",
            title: "Intelligence",
            imageUrl: "assets/images/content/intelligence/tile.png",
            providerImageUrl:
                "assets/images/provider/sky_one.png",
            hero: {
                mode: "standard",
                imageUrl: "https://images.metadata.sky.com/pd-image/02d6f8bd-1298-4cb6-8c8b-f83c33a43f6e/background/{width}",
                logoUrl: "assets/images/provider/sky_one.png",
                title: "Intelligence",
                synopsis: "Somewhere between MI5 and MI6, an arrogant, maverick NSA liaison arrives from the U.S. to join a ragtag bureaucracy of cybersecurity misfits.",
                metadata: "2020    2 seasons"
            }
        },
        {
            type: "landscape",
            title: "The Grand Tour - Lochdown",
            imageUrl: "assets/images/content/the_grand_tour_lochdown/tile.png",
            providerImageUrl:
                "assets/images/provider/prime_video.png",
            hero: {
                mode: "standard",
                imageUrl: "assets/images/content/the_grand_tour_lochdown/hero.jpg",
                logoUrl: "assets/images/provider/prime_video.png",
                title: "The Grand Tour - Lochdown",
                synopsis:
                    "Confined to the UK by Covid, Jeremy, Richard and James embark on a Lockdown Special to find out just why American 70s cars never took off in Britain.",
                metadata: "2021    4 seasons"
            }
        },
        {
            type: "landscape",
            title: "Law & Order: Special Victims Unit",
            imageUrl: "assets/images/content/law_and_order_special_victims_unit/tile.png",
            providerImageUrl:
                "assets/images/provider/sky_witness.png",
            hero: {
                mode: "standard",
                imageUrl: "https://images.metadata.sky.com/pd-image/128c5028-fb51-4abd-8dbd-17300923eb04/background/{width}",
                logoUrl: "assets/images/provider/sky_witness.png",
                title: "Law & Order: Special Victims Unit",
                synopsis:
                    "A special victims unit of NYPD detectives solve cases based on real crimes. At the same time, they try not to let the dark side of investigation affect their personal lives.",
                metadata: "1999    22 seasons"
            }
        },
        {
            type: "landscape",
            title: "Soul",
            imageUrl: "assets/images/content/soul/tile.png",
            providerImageUrl:
                "assets/images/provider/disney_plus.png",
            hero: {
                mode: "standard",
                titleImageUrl: "assets/images/content/soul/title.png",
                imageUrl: "assets/images/content/soul/hero.jpg",
                logoUrl: "assets/images/provider/disney_plus.png",
                title: "Soul",
                synopsis:
                    "Jamie Foxx leads an all-star cast in this hilarious, heart-filled adventure. Pixar’s \"Soul\" introduces Joe, who lands the gig of his life at the best jazz club in town. But one misstep lands Joe in a fantastical place: The Great Before. There, he teams up with soul 22 (Tina Fey), and together they find the answers to some of life’s biggest questions.",
                metadata: "2020    1h 49m"
            }
        }
    ]
};

export default rail;
