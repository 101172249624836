import React from "react";
import { useRxState, useStores } from "@ixd-group/react-utils";
import { Header } from "@ixd-group/llama-components";

import { Stores } from "../../../stores"
export type HeaderProps = {
  logoUrl?: string;
}
const HeaderController: React.FC<HeaderProps> = ({logoUrl}) => {
  const { clock } = useStores<Stores>();
  const time = useRxState(clock.time$);
  
  return <Header time={time} logoImage={logoUrl} />;
};

export default HeaderController;
