import styled from "@emotion/styled";

import { grid, rail } from "../../../../constants/dimensions";

export const Container = styled.div<{ 
    isFocused: boolean;
    showTitle: boolean; 
}>`
    margin-left: 96px;
    margin-top: ${p => rail.marginTop(p.isFocused || p.showTitle)}px;
`;

export const TileContainer = styled.div<{ isFocused: boolean }>`
    margin-right: ${p => p.isFocused ? 0 : grid.column.gap}px;
`;