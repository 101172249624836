import type { CategoryPage } from "../../../types/page.type";

import next from "./next";
import categories from "./categories";
import onNow from "./onNow";
import playlist from "./playlist";
import catchUp from "./catchUp";
import top10 from "./top10";
import skyCinema from "./skyCinema";
import settings from "./settings";

export default {
    id: "home",
    rails: [
        next,
        categories,
        onNow,
        playlist,
        catchUp,
        top10,
        skyCinema,
        settings
    ],
    title: "Home",
    type: "category"
} as CategoryPage;


