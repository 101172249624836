import type Rail from "../../../types/rail.type";

const rail: Rail = {
  template: "tile-landscape",
  title: "Kids club",
  items: [
    {
      type: "landscape",
      title: "Fun Ways To Learn",
      imageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/8550f3405332427c4cafba30c9614bbc/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Fun Ways To Learn",
        synopsis: "Discover a huge selection of educational content no matter what stage you’re at! So, whether you’re tackling literacy and numbers or complex subjects like science and history, we’ve got you covered!",
        imageUrl: "http://static.skyq-b.interactive.sky.com/config-published-content/apps/media-2.x/8073ad93a2af7980b1ff262a62273f3b/{width}/{height}/hero.jpg"
      },
    },
    {
      type: "landscape",
      title: "Making & Doing",
      imageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/8550f34053fc247bd6023995a3ca7435/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Making & Doing",
        synopsis: "From cool arts and crafts to magic skills and more, this collection has everything you need to get creative and impress your friends and family! ",
        imageUrl: "http://static.skyq-b.interactive.sky.com/config-published-content/apps/media-2.x/01ff5ca09bd4eac584c67950055621de/{width}/{height}/hero.jpg"
      },
    },
    {
      type: "landscape",
      title: "Sing & Dance",
      imageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/8550f3405369e9b476363ca236ec2781/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Sing & Dance",
        synopsis: "From sing-along classics to shows you can’t help but get up and dance to, this collection of musical hits is sure to make you smile!",
        imageUrl: "http://static.skyq-b.interactive.sky.com/config-published-content/apps/media-2.x/b91c0c97d72a3e2f4a5e17b8bac4a28e/{width}/{height}/hero.jpg",
      },
      action: {
        type: "GO_TO_PAGE",
        pageType: "show",
        pageId: "genericKidsUpsell"
      }
    },
    {
      type: "landscape",
      title: "Body & Mind",
      imageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/8550f340536d00743cf22b2c914280aa/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Body & Mind",
        synopsis: "Get up and get your body moving with this selection of fitness and wellness shows designed to keep you healthy, from wild workouts to winding down after a busy day. ",
        imageUrl: "http://static.skyq-b.interactive.sky.com/config-published-content/apps/media-2.x/9524436e31ac25e566cab41b43672af6/{width}/{height}/hero.jpg",
      },
    },
    {
      type: "landscape",
      title: "Quizzes & Games",
      imageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/8550f34053fa5c3df6c76b715634c0b1/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Quizzes & Games",
        synopsis: "Get hands on with this fantastic collection of games and quizzes, ideal for that well-deserved break or when you want to test your knowledge against friends and family.",
        imageUrl: "http://static.skyq-b.interactive.sky.com/config-published-content/apps/media-2.x/8bde717d7c307e45a233e98f86b5ae40/{width}/{height}/hero.jpg"
      },
      action: {
        type: "GO_TO_PAGE",
        pageType: "show",
        pageId: "genericKidsUpsell"
      }
    },
  ]
};

export default rail;
