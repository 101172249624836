import { FC } from "react";
import styled from "@emotion/styled";

const TileDiv = styled.div`
  width: 560px;
  height: 560px;
  overflow: hidden;
  border-radius: 10px;
  margin: 0 32px 0 0;
`;

type TopImageProps = {
  image: string;
};

const TopImage = styled.div<TopImageProps>`
  width: 100%;
  height: 50%;
  background: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
`;

const BottomText = styled.div`
  width: 100%;
  height: 50%;
  background-color: rgba(241, 241, 241, 0.1);
  color: #f1f1f1;
  font-family: "Sky Text Regular";

  h1 {
    font-family: "Sky Text Medium";
    font-size: 34px;
    margin: 0 0 8px 0;
    font-weight: 500;
  }
  h2 {
    margin: 0 0 16px 0;
    font-size: 28px;
    font-weight: 500;
    font-family: "Sky Text Regular";
  }
  padding: 32px;
`;

type FanTileProps = {
  image: string;
  title: string;
  account: string;
  info: string;
};

const FanTile: FC<FanTileProps> = ({ image, title, account, info }) => {
  return (
    <TileDiv>
      <TopImage image={image} />
      <BottomText>
        <div style={{ height: "90%" }}>
          <h1>{title}</h1>
          <h2>{account}</h2>
        </div>
        <div style={{ height: "10%" }}>
          <p>{info}</p>
        </div>
      </BottomText>
    </TileDiv>
  );
};

export default FanTile;
