import React from "react";
import {
  useButtons,
  useRxState,
  useServices,
  useStores,
} from "@ixd-group/react-utils";

import { railsPage } from "../../../../../constants/dimensions";
import { Services } from "../../../services";
import { Stores } from "../../../stores";
import Rails from "../";

const RailsController: React.FC = () => {
  const { buttonHandler } = useServices<Services>();
  const { isFocused$, railsStore } = useStores<Stores>();
  const [isFocused, railIndex, rails] = useRxState([
    isFocused$,
    railsStore.railIndex$,
    railsStore.rails$,
  ]);

  useButtons(["browse", "rails"], buttonHandler, isFocused, {
    BACK: () => {
      if (railIndex === 0) {
        return "continue";
      }
      railsStore.setRailIndex(0);
    },
    DOWN: () => railsStore.nextRail(),
    UP: () => {
      if (railIndex === 0) {
        return "continue";
      }
      railsStore.prevRail();
    },
  });

  const yLine =
    railsPage.yLines[rails[railIndex]?.template] ?? railsPage.yLines.default;

  const indices = rails.reduce(
    (obj, _, index) => ({
      ...obj,
      [index]: railsStore.itemIndex$(index).get(),
    }),
    {}
  );

  return (
    <Rails index={railIndex} indices={indices} rails={rails} yLine={yLine} />
  );
};

export default RailsController;
