import React from "react";
import { Button as LlamaButton } from "@ixd-group/llama-components";

import { Container, Small } from "./index.styles";

export type ButtonProps = {
  isFocused: boolean;
  subtitle?: string;
  title: string;
}

const Button: React.FC<ButtonProps> = ({ 
  isFocused,
  subtitle,
  title
}) => {
  return (
    <LlamaButton
      focused={isFocused}
      height={!!subtitle ? 150 : 96}
      style={{ marginTop: 65 }}
      width={!!subtitle ? 610 : 370}
    >
      <Container hasSubtitle={!!subtitle}>
        <div dangerouslySetInnerHTML={{ __html: title }} />
        {!!subtitle && <Small dangerouslySetInnerHTML={{ __html: subtitle }} />}
      </Container>
    </LlamaButton>
  );
}

export default Button;