import type Rail from "../../../types/rail.type";

const rail: Rail = {
    template: "tile-category",
    items: [
        {
            type: "category",
            title: "TV Shows",
            hero: {
                mode: "standard",
                imageUrl: "assets/images/categories/tv-shows-hero.jpg",
                synopsis:
                    "From award-winning Sky TV dramas and documentaries, to epic Netflix originals and never before seen Disney+ series, find your favourite TV shows and discover new things to watch.",
                title: "TV Shows",
            },
        },
        {
            type: "category",
            title: "Movies",
            hero: {
                mode: "standard",
                imageUrl: "assets/images/categories/movies-hero.jpg",
                synopsis:
                    "Catch the latest premieres, rewatch a family favourite or discover a classic you missed the first time – find movies for all the family’s viewing taste across Sky, Netflix, Disney+ and more.",
                title: "Movies",
            },
        },
        {
            type: "category",
            title: "Sports",
            action: { type: "GO_TO_PAGE", pageType: "category", pageId: "skySports" },
            hero: {
                mode: "standard",
                imageUrl: "assets/images/categories/sports-hero.jpg",
                synopsis:
                    "The biggest games, F1 races, golf, cricket and more on Sky Sports and BT Sport – with all Sky Sports channels showing in glorious HD.",
                title: "Sports",
            },
        },
        {
            type: "category",
            title: "Kids",
            action: { type: "GO_TO_PAGE", pageType: "category", pageId: "skyKids" },
            hero: {
                mode: "standard",
                imageUrl: "assets/images/categories/kids-hero.jpg",
                synopsis: "Eleven kids channels to keep your children captivated.",
                title: "Kids",
            },
        },
        {
            type: "category",
            title: "Music",
            hero: {
                mode: "standard",
                imageUrl: "assets/images/categories/music-hero.jpg",
                synopsis: "Music for every type of listener.",
                title: "Music",
            },
        }
    ],
    showGradient: true
};

export default rail;