import type Rail from "../../../types/rail.type";

const rail: Rail = {
  template: "tile-landscape",
  title: "All your favourites",
  items: [
    {
      type: "landscape",
      title: "All-Action Anime",
      imageUrl: "http://static.skyq-b.interactive.sky.com/config-published-content/apps/media-2.x/aa33fc8b5cb6e9f38ac53f260471d03a/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "All-Action Anime",
        synopsis: "The best Anime series, from Sonic Boom and Yu-Gi-Oh! to Bakugan and Pokémon – it’s non-stop brawls and battles in this out-of-this-world collection. Suitable for children aged 8+.",
        imageUrl: "https://images.metadata.sky.com/pd-image/89497b73-28f4-4dbb-908b-d21d60b59b17/background/{width}"
      },
      action: {
        type: "GO_TO_PAGE",
        pageType: "show",
        pageId: "genericKidsUpsell"
      }
    },
    {
      type: "landscape",
      title: "We love Scooby-Doo!",
      imageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/8550f34053c89be20210e98093809efa/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "We love Scooby-Doo!",
        synopsis: "Jinkies! Jump into a world of mystery alongside Shaggy, Daphne, Fred, Velma, and, of course, loveable hound Scooby-Doo with this collection of whodunits, capers and crimes!",
        imageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/788e2d1758191020c2c6289f34c91740/{width}/{height}/hero.jpg"
      },
      action: {
        type: "GO_TO_PAGE",
        pageType: "show",
        pageId: "genericKidsUpsell"
      }
    },
    {
      type: "landscape",
      title: "We Love PAW Patrol ",
      imageUrl: "https://images.metadata.sky.com/pd-image/27560723-1b7b-4116-bb7b-c557aef387fa/16-9/{width}",
      hero: {
        mode: "standard",
        title: "We Love PAW Patrol ",
        synopsis: "Discover brand-new series of PAW Patrol all in one place, from bite-sized mini adventures and Ryder’s favourite ever rescues to cooking shows designed to bring fun recipes to life!",
        imageUrl: "https://images.metadata.sky.com/pd-image/27560723-1b7b-4116-bb7b-c557aef387fa/background/{width}"
      },
      action: {
        type: "GO_TO_PAGE",
        pageType: "show",
        pageId: "genericKidsUpsell"
      }
    },
    {
      type: "landscape",
      title: "DC Superheroes",
      imageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/8550f34053e11c3d64eefab9d949d5e2/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "DC Superheroes",
        synopsis: "Fly into action with this selection of the best DC superheroes around – includes Teen Titans Go!, Justice League Unlimited and DC Superhero Girls.",
        imageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/788e2d17586cf260ddcbc0601332eaf1/{width}/{height}/hero.jpg"
      },
      action: {
        type: "GO_TO_PAGE",
        pageType: "show",
        pageId: "genericKidsUpsell"
      }
    },
    {
      type: "landscape",
      title: "Little Baby Bum & Friends ",
      imageUrl: "http://static.skyq-b.interactive.sky.com/config-published-content/apps/media-2.x/dec401cc39096b3913ec74ab47371055/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Little Baby Bum & Friends ",
        synopsis: "Enjoy more fun sing-alongs with Little Baby Bum and join in as friends old and new come together to learn and play in Mia's Magic Playground, Digley & Daisy and more! ",
        imageUrl: "http://static.skyq-b.interactive.sky.com/config-published-content/apps/media-2.x/f439b9b73b24a9c067fb8226603fdb67/{width}/{height}/hero.jpg"
      },
      action: {
        type: "GO_TO_PAGE",
        pageType: "show",
        pageId: "genericKidsUpsell"
      }
    },
  ]
};

export default rail;
