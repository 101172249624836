import React from "react";
import {
  useButtons,
  useRxState,
  useServices,
  useStores,
  useSubscribe
} from "@ixd-group/react-utils";
import { buttonOperators } from "@ixd-group/common-services";

import Midroll from "../../Midroll";
import type { default as RailType } from "../../../../../types/rail.type";
import type { ButtonTile } from "../../../../../types/tile.type";
import { tiles } from "../../../../../constants/dimensions";
import { shouldShowRailTitle } from "../../../../../utilities";
import { Services } from "../../../services";
import { Stores } from "../../../stores";
import Rail from "../";

export type RailControllerProps = {
  index: number;
  rail: RailType;
}

const RailController: React.FC<RailControllerProps> = ({ index, rail }) => {
  const { buttonHandler, buttonListener, pubSub } = useServices<Services>();
  const { isFocused$, railsStore } = useStores<Stores>();
  const [isFocused, itemIndex, railIndex] = useRxState([
    isFocused$,
    railsStore.itemIndex$(index),
    railsStore.railIndex$
  ]);

  const isRailFocused = railIndex === index && isFocused;

  useButtons(["browse", "rails", index.toString()], buttonHandler, isRailFocused, {
    BACK: () => {
      if (itemIndex === 0) {
        return "continue";
      }
      railsStore.setItemIndex(index, 0);
    }
  });

  useSubscribe(
    buttonListener.stream$.pipe(
      buttonOperators.longPressAutofire({ intervalMs: 500 })
    ), (button) => {
      if (isRailFocused) {
        const tileWidth = tiles[rail?.items?.[itemIndex]?.type]?.width;
        switch (button) {
          case "LEFT":
            railsStore.prevItem(index);
            if (itemIndex !== 0) {
              pubSub.publish("moveLens", { direction: "left", tileWidth });
            }
            break;
          case "RIGHT":
            railsStore.nextItem(index);
            if (itemIndex < rail.items.length - 1) {
              pubSub.publish("moveLens", { direction: "right", tileWidth });
            }
            break;
        }
      }
    }
  );

  switch (rail.template) {
    case "midroll":
      const { hero, title } = rail.items[0] as ButtonTile;
      return (
        <Midroll 
          button={title}
          focused={isRailFocused}
          imageUrl={hero?.imageUrl}
          synopsis={hero?.synopsis}
          title={hero?.title}
        />
      );
    default:
      return (
        <Rail
          {...rail}
          index={itemIndex}
          isFocused={isRailFocused}
          showTitle={shouldShowRailTitle(rail, itemIndex)}
        />
      );
  }
};

export default RailController;
