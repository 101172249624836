import type Rail from "../../../types/rail.type";

const rail: Rail = {
    template: "tile-cover",
    title: "Turn On The Subtitles",
    items: [
        {
            type: "cover",
            imageUrl: "http://static.skyq-b.interactive.sky.com/config-published-content/apps/media-2.x/b7790a1d75d8567f29859d36918b1c04/{width}/{height}/item.jpg",
            hero: {
                mode: "standard",
                title: "Turn On The Subtitles",
                synopsis: "Did you know that kids read more when you turn on the subtitles? Braydon explains how you can increase your child's literacy ability with the click of a button.",
                imageUrl: "http://static.skyq-b.interactive.sky.com/config-published-content/apps/media-2.x/6b7b62eb7f649b7934b9178113e51794/{width}/{height}/hero.jpg"
            },
        },
        {
            type: "cover",
            imageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/8550f340530bfc72c2084a7eede55452/{width}/{height}/item.jpg",
            hero: {
                mode: "standard",
                title: "Big Cats: Wild Files ",
                synopsis: "Patrick Aryee goes in search of the the fastest, strongest, cutest and most fearsome big cats out there in this eye-catching and fact-filled kids series.",
                imageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/788e2d17582543fe7d3cbe0128085c8a/{width}/{height}/hero.jpg"
            },
        },

        {
            type: "cover",
            imageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/8550f34053da2956bb1adac1fc67f8b8/{width}/{height}/item.jpg",
            hero: {
                mode: "standard",
                title: "Avatar: The Last Airbender",
                synopsis: "The gang hits the road in the Mystery Machine, chasing fun and adventure. But monsters and mayhem keep getting in the way.",
                imageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/788e2d17584842a7dd355fc8bb8e3ced/{width}/{height}/hero.jpg"
            },
        },



    ]
};

export default rail;