import React from "react";
import { ButtonHandler } from "@ixd-group/common-services";
import { ModuleContext } from "@ixd-group/react-utils";

import type Action from "../../types/action.type";
import type HistoryItem from "../../types/history-item.type";
import type Rail from "../../types/rail.type";
import type Tile from "../../types/tile.type";
import RailsController from "./components/Rails/RailsController";
import { Services } from "./services";
import { useRailsPageLogic } from "./logic";

export type RailsPageProps = {
    buttonHandler?: ButtonHandler;
    isFocused: boolean;
    onBack?: () => void;
    onSelect?: (action?: Action, indices?: HistoryItem["indices"]) => void;
    onTileChange?: (tile?: Tile) => void;
    page: Extract<HistoryItem, { rails: Rail[] }>;
    pubSub: Services["pubSub"];
}

const RailsPage: React.FC<RailsPageProps> = ({ 
    buttonHandler, 
    isFocused, 
    onBack,
    onSelect,
    onTileChange,
    page,
    pubSub
}) => {
    const { services, stores } = useRailsPageLogic(
        buttonHandler,
        isFocused,
        onBack,
        onSelect,
        onTileChange,
        page,
        pubSub
    );

    return (
        <ModuleContext.Provider value={{ services, stores }}>
            <RailsController />
        </ModuleContext.Provider>
    );
}

export default RailsPage;
