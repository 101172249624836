import type Rail from "../../../types/rail.type";

const rail: Rail = {
  template: "tile-landscape",
  title: "Top picks for kids",
  items: [
    {
      type: "landscape",
      title: "Dreamflight: Calming Stories",
      imageUrl: "https://images.metadata.sky.com/pd-image/26044bab-9b39-410d-b918-420fcbb62fdc/16-9/{width}",
      hero: {
        mode: "standard",
        tempTitle: "Kids",
        title: "Dreamflight: Calming Stories",
        synopsis: "Bears: Shake to Wake: Join the Grizzly bears of North America as they wake up from hibernation to a glowing world transformed by spring. Narrated by Russell Tovey.",
        imageUrl: "https://images.metadata.sky.com/pd-image/26044bab-9b39-410d-b918-420fcbb62fdc/background/{width}",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_skykids/300",
      },
      providerImageUrl: "https://images.metadata.sky.com/pd-logo/skychb_skykids/300",
      action: { type: "GO_TO_PAGE", pageType: "show", pageId: "dreamflight" }
    },

    {
      type: "landscape",
      title: "Topsy and Tim",
      imageUrl: "https://images.metadata.sky.com/pd-image/643ec2f4-d929-4b87-8dba-22e9975494c1/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Topsy and Tim",
        synopsis: "The Underwoods, a normal, good-hearted family, have their lives thrown off kilter when the second most wanted bad guy in the galaxy moves in with them under witness protection.",
        imageUrl: "https://images.metadata.sky.com/pd-image/643ec2f4-d929-4b87-8dba-22e9975494c1/background/{width}",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_cbeebies/300",
      },
      providerImageUrl: "https://images.metadata.sky.com/pd-logo/skychb_cbeebies/300",
      action: { type: "GO_TO_PAGE", pageType: "show", pageId: "topsyAndTim" }
    },
    {
      type: "landscape",
      title: "Master Moley By Royal Invitation",
      imageUrl: "https://images.metadata.sky.com/pd-image/fa8fab5c-88c1-4447-b1ac-d7c601e50d22/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Master Moley By Royal Invitation",
        synopsis: "Legend tells that the Mystical Manual of Moley Magic awaits a chosen mole to bring peace between humans and moles.",
        imageUrl: "https://images.metadata.sky.com/pd-image/fa8fab5c-88c1-4447-b1ac-d7c601e50d22/background/{width}",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_boomerang/300",
      },
      providerImageUrl: "https://images.metadata.sky.com/pd-logo/skychb_boomerang/300",
      action: {
        type: "GO_TO_PAGE",
        pageType: "show",
        pageId: "genericKidsUpsell"
      }
    },
    {
      type: "landscape",
      title: "4 O'Clock Club",
      imageUrl: "https://images.metadata.sky.com/pd-image/26340d3f-e256-402a-bb0e-3e27a5152840/16-9/{width}",
      hero: {
        mode: "standard",
        title: "4 O'Clock Club",
        synopsis: "Children's comedy with Khalil Madovi and Doc Brown about the rivalry between two brothers who attend the same school - one as a pupil and the other as an English teacher.",
        imageUrl: "https://images.metadata.sky.com/pd-image/26340d3f-e256-402a-bb0e-3e27a5152840/background/{width}",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_cbbc/300",
      },
      providerImageUrl: "https://images.metadata.sky.com/pd-logo/skychb_cbbc/300",
      action: { type: "GO_TO_PAGE", pageType: "show", pageId: "fourOclockClub" }
    },
    {
      type: "landscape",
      title: "Labuntina",
      imageUrl: "https://images.metadata.sky.com/pd-image/af837a6e-fc95-48ba-917d-b109774d2691/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Labuntina",
        synopsis: "Sing along with Lili Fish, Judi Bee and Kodi Fox and learn about numbers, ABC, colours, animals and much more, in the magical world of Labuntina!",
        imageUrl: "https://images.metadata.sky.com/pd-image/af837a6e-fc95-48ba-917d-b109774d2691/background/{width}",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_skykids/300",
      },

      providerImageUrl: "https://images.metadata.sky.com/pd-logo/skychb_skykids/300",
      action: {
        type: "GO_TO_PAGE",
        pageType: "show",
        pageId: "genericKidsUpsell"
      }
    },
    {
      type: "landscape",
      title: "Teen Titans Go!",
      imageUrl: "http://images.metadata.sky.com/pd-image/cf14c54c-5863-4227-8f8b-642cc20c158f/16-9/456?providername=cartoonnetwork",
      hero: {
        mode: "standard",
        title: "Teen Titans Go!",
        synopsis: "Adventures of Robin, Cyborg, Starfire, Raven and Beast Boy. They are superheroes who save the day, but what happens when they're not fighting crime?",
        imageUrl: " http://images.metadata.sky.com/pd-image/cf14c54c-5863-4227-8f8b-642cc20c158f/background/1428",
        logoUrl: "http://images.metadata.sky.com/pd-logo/skychb_cartoonnetwork/365/205",
      },
      providerImageUrl: "http://images.metadata.sky.com/pd-logo/skychb_cartoonnetwork/365/205",
      action: {
        type: "GO_TO_PAGE",
        pageType: "show",
        pageId: "genericKidsUpsell"
      }
    },



  ]
};

export default rail;
