import type Rail from "../../../types/rail.type";

const rail: Rail = {
  template: "tile-landscape",
  title: "Premier League Highlights",
  items: [
    {
      type: "landscape",
      title: "Lacazette rescues a point for Arsenal",
      imageUrl: "http://e3.365dm.com/21/10/456x257/skysports-arsenal-palace-highlights_5551168.jpg?20211018220925",
      // providerImageUrl: " http://images.metadata.sky.com/pd-logo/skychb_skyspfootball/365/205",
      hero: {
        mode: "standard",
        title: "Lacazette rescues a point for Arsenal",
        synopsis: "FREE TO WATCH: Highlights from Arsenal's draw with Crystal Palace in the Premier League.",
        imageUrl: "https://cdn.skyq.sky.com/mashup/images/uk/condatis/sports/NewsPL-HL.jpg",
        // logoUrl: " http://images.metadata.sky.com/pd-logo/skychb_skyspfootball/365/205"
      }
    },
    {
      type: "landscape",
      title: "Newcastle's new era starts in defeat",
      imageUrl: "http://e3.365dm.com/21/10/456x257/skysports-tottenham-newcastle_5549930.jpg?20211017193205",
      // providerImageUrl: 'http://images.metadata.sky.com/pd-logo/skychb_skyspgolf/365/205',
      hero: {
        mode: "standard",
        title: "Newcastle's new era starts in defeat",
        synopsis: "FREE TO WATCH: Highlights from Tottenham's win over Newcastle in the Premier League.",
        imageUrl: "https://cdn.skyq.sky.com/mashup/images/uk/condatis/sports/NewsPL-HL.jpg",
        // logoUrl: 'http://images.metadata.sky.com/pd-logo/skychb_skyspgolf/365/205'
      }
    },
    {
      type: "landscape",
      title: "Ogbonna header seals West Ham win",
      imageUrl: "http://e3.365dm.com/21/10/456x257/skysports-everton-west-ham_5549682.jpg?20211017162711",
      // providerImageUrl: 'http://images.metadata.sky.com/pd-logo/skychb_skyspfootball/365/205',
      hero: {
        mode: "standard",
        title: "Ogbonna header seals West Ham win",
        synopsis: "FREE TO WATCH: Highlights from West Ham's win against Everton in the Premier League.",
        imageUrl: "https://cdn.skyq.sky.com/mashup/images/uk/condatis/sports/NewsPL-HL.jpg",
        // logoUrl: 'http://images.metadata.sky.com/pd-logo/skychb_skyspfootball/365/205'
      }
    },
    {
      type: "landscape",
      title: "World-class Mendy denies Brentford a point",
      imageUrl: "http://e3.365dm.com/21/10/456x257/skysports-chelsea-brentford_5548854.jpg?20211016193729",
      // providerImageUrl: 'http://images.metadata.sky.com/pd-logo/skychb_skyspcricket/365/205',
      hero: {
        mode: "standard",
        title: "World-class Mendy denies Brentford a point",
        synopsis: "FREE TO WATCH: Highlights from Chelsea’s win at Brentford in the Premier League.",
        imageUrl: "https://cdn.skyq.sky.com/mashup/images/uk/condatis/sports/NewsPL-HL.jpg"
        // logoUrl: 'http://images.metadata.sky.com/pd-logo/skychb_skyspcricket/365/205'
      }
    },
    {
      type: "landscape",
      title: "Leicester hit four to end Man Utd's away run",
      imageUrl: "http://e3.365dm.com/21/10/456x257/skysports-leicester-man-utd_5548483.jpg?20211016170416",
      // providerImageUrl: 'http://images.metadata.sky.com/pd-logo/skychb_skysparena/365/205',
      hero: {
        mode: "standard",
        title: "Leicester hit four to end Man Utd's away run",
        synopsis: "FREE TO WATCH: Highlights from Leicester's win over Manchester United in the Premier League.",
        imageUrl: "http://images.metadata.sky.com/pd-image/17591d3a-fab3-4ce3-adae-18cc16cf29f6/background/1428",
        // logoUrl: 'http://images.metadata.sky.com/pd-logo/skychb_skysparena/365/205'
      }
    },
  ]
};

export default rail;
