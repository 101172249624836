import React from "react";
import { ButtonHandler, PubSub } from "@ixd-group/common-services";

import type { default as PageType } from "../../../../types/page.type";
import type Action from "../../../../types/action.type";
import type HistoryItem from "../../../../types/history-item.type";
import type Tile from "../../../../types/tile.type";

import RailsPage from "../../../RailsPage";
import { Wrapper } from "./index.styles";
import ShowPage from "../../../ShowPage";
import FanPage from "../../../FanPage";
import VideoPage from "../../../VideoPage";

export type PageProps = {
  buttonHandler: ButtonHandler;
  isFocused: boolean;
  onBack?: () => void;
  onSelect?: (action?: Action, indices?: HistoryItem["indices"]) => void;
  onTileChange?: (tile?: Tile) => void;
  page: PageType;
  pubSub: PubSub;
  isSubscribed: boolean;
  onSubscribe: () => void;
};

const Page = React.forwardRef<HTMLDivElement, PageProps>(
  (
    {
      buttonHandler,
      isFocused,
      onBack,
      onSelect,
      onTileChange,
      page,
      pubSub,
      isSubscribed,
      onSubscribe,
    },
    ref
  ) => {
    const pageComponent = (
      page: PageType,
      isSubscribed: boolean,
      onSubscribe: () => void
    ) => {
      switch (page.type) {
        case "category":
        case "product":
          return (
            <RailsPage
              buttonHandler={buttonHandler}
              isFocused={isFocused}
              onBack={onBack}
              onSelect={onSelect}
              onTileChange={onTileChange}
              page={page}
              pubSub={pubSub}
            />
          );
        case "show":
          return (
            <ShowPage
              buttonHandler={buttonHandler}
              upsellImageUrl={page.upsellImageUrl}
              confirmationImageUrl={page.confirmationImageUrl}
              showpageImageUrl={page.showpageImageUrl}
              isSubscribed={isSubscribed}
              onSubscribe={onSubscribe}
            />
          );
        case "fan":
          return (
            <FanPage
              buttonHandler={buttonHandler}
              showpageImageUrl={page.showpageImageUrl}
              onSelect={onSelect}
              media={page.media}
              hero={page.hero}
              firstPageWidgets={page.firstPageWidgets}
              extras={page.extras}
            />
          );

        case "video":
          return (
            <VideoPage
              buttonHandler={buttonHandler}
              image={page.image}
              video={page.video}
            />
          );
        default:
          return null;
      }
    };

    return (
      <Wrapper ref={ref}>
        {pageComponent(page, isSubscribed, onSubscribe)}
      </Wrapper>
    );
  }
);

export default Page;
