import type Rail from "../../../types/rail.type";

const rail: Rail = {
  template: "tile-landscape",
  title: "Latest News",
  items: [
    {
      type: "landscape",
      title: "Estonia 0-1 Wales",
      imageUrl: "http://img.skysports.com/21/10/456x257/skysports-estonia-wales_5543514.jpg?20211011214617",
      hero: {
        mode: "standard",
        title: "Estonia 0-1 Wales",
        synopsis: "Highlights from the World Cup qualifying match between Estonia and Wales.",
        imageUrl: "http://cdn.skyq.sky.com/mashup/images/NewsRailMain2021-HS.jpg",
      }
    },
    {
      type: "landscape",
      title: "Werner scores stunner for Germany!",
      imageUrl: " http://img.skysports.com/21/10/456x257/image_5543486.jpg?20211011213408",
      hero: {
        mode: "standard",
        title: "Werner scores stunner for Germany!",
        synopsis: "Timo Werner scored a cracker in Germany's 4-0 win over North Macedonia.",
        imageUrl: "http://cdn.skyq.sky.com/mashup/images/NewsRailMain2021-HS.jpg",
      }
    },
    {
      type: "landscape",
      title: "Taylor-Brown picks up silver in triathlon",
      imageUrl: "assets/images/content/olympics_daily/tile3.png",
      hero: {
        mode: "standard",
        title: "Tokyo 2020: Triathlon",
        synopsis: "Taylor-Brown picks up silver in triathlon",
        imageUrl: "https://images.metadata.sky.com/pd-image/d04bfd1d-1a52-4112-954a-46a9655a28e2/background/{width}",
      }
    },
    {
      type: "landscape",
      title: "Netherlands 6-0 Gibraltar",
      imageUrl: "http://img.skysports.com/21/10/456x257/skysports-netherlands-6-0-gibraltar_5543526.jpg?20211011215534",
      hero: {
        mode: "standard",
        title: "Netherlands 6-0 Gibraltar",
        synopsis: "Highlights of the World Cup qualifier between Netherlands and Gibraltar.",
        imageUrl: "http://cdn.skyq.sky.com/mashup/images/NewsRailMain2021-HS.jpg",
      }
    },
    {
      type: "landscape",
      title: "North Macedonia 0-4 Germany",
      imageUrl: "http://img.skysports.com/21/10/456x257/skysports-north-macedonia-germany_5543525.jpg?20211011215504",
      hero: {
        mode: "standard",
        title: "North Macedonia 0-4 Germany",
        synopsis: "Highlights of the European World Cup qualifying match between North Macedonia and Germany.",
        imageUrl: "http://cdn.skyq.sky.com/mashup/images/NewsRailMain2021-HS.jpg",
      }
    },
  ]
};

export default rail;
