import type Rail from "../../../types/rail.type";

const rail: Rail = {
    template: "tile-category",
    title: "Browse by sport",
    items: [
        {
            type: "category",
            title: "Football",
            hero: {
                mode: "standard",
                imageUrl: "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/53f29a21a584fd992efc91d67d2fe6c5/hero.jpg",
                synopsis:
                    "Follow all the latest news, goals, interviews and more from around the wide world of football, including the Premier League, EFL, SPFL and beyond.",
                title: "Football",
            },
        },
        {
            type: "category",
            title: "Formula 1",
            hero: {
                mode: "standard",
                imageUrl: "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/ffeb61627b7fefb2ae7896b1b07154f2/hero.jpg",
                synopsis:
                    "Follow all of the twists and turns of the 2020 F1 season on Sky Sports, the exclusive home of all live Formula 1. With race highlights, features, interviews and more.",
                title: "Formula 1",
            },
        },
        {
            type: "category",
            title: "Boxing",
            hero: {
                mode: "standard",
                imageUrl: "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/4ca4a41e3c62b034fe0d71426eff9ae8/hero.jpg",
                synopsis:
                    "Brilliant boxing, including a collection of world title fights, the best of Britain, and more.",
                title: "Boxing",
            },
        },
        {
            type: "category",
            title: "Cricket",
            hero: {
                mode: "standard",
                imageUrl: "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/51d0dfd2efb28a3da96b4632a2ff9451/hero.jpg",
                synopsis: "Enjoy this collection of cracking cricket, featuring England internationals, ICC tournaments, county cricket and more.",
                title: "Cricket",
            },
        },
        {
            type: "category",
            title: "Golf",
            hero: {
                mode: "standard",
                imageUrl: "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/c4e539c82f461916bd38474b57d7051a/hero.jpg",
                synopsis: "The definitive home of golf. Open here to enjoy the best of golf’s Majors, the European and PGA Tours, Ryder Cup and much more.",
                title: "Golf",
            },
        }
    ]
};

export default rail;