import React from "react";
import { Button, Hero } from "@ixd-group/llama-components";

import { rail as railDims } from "../../../../constants/dimensions";

export type MidrollProps = {
  button?: string;
  focused: boolean;
  imageUrl?: string;
  synopsis?: string;
  title?: string;
}

const Midroll: React.FC<MidrollProps> = ({ button, focused, ...rest }) => {
  return (
    <div>
      <Hero
        height={700}
        mode="midroll"
        style={{ marginTop: railDims.marginTop(true) }}
        {...rest}
      >
        {!!button && (
          <Button
            focused={focused}
            style={{ marginTop: 65 }}
          >
            {button}
          </Button>
        )}
      </Hero>
    </div>
  );
}

export default Midroll;