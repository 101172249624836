import type Rail from "../../../types/rail.type";

const rail: Rail = {
  template: "tile-category",
  items: [
    {
      type: "category",
      title: " Search",
      
      hero: {
        mode: "standard",
        title: "Search",
        synopsis: "Find shows, movies, actors, competitions and teams.",
        imageUrl: "assets/images/settings/forgotten-pin.jpg",
      },
    },
    {
      type: "category",
      title: "Settings",
      hero: {
        mode: "titleArt",
        imageUrl: "assets/images/settings/settings.jpg",
      },
    },
    {
      type: "category",
      title: "Help",
      hero: {
        mode: "titleArt",
        imageUrl: "assets/images/settings/help.jpg",
      },
    },
    {
      type: "category",
      title: "My Account",
      hero: {
        mode: "titleArt",
        imageUrl: "assets/images/settings/myaccount.jpg",
      },
    },
    {
      type: "category",
      title: "Your Sky Bill",
      hero: {
        mode: "titleArt",
        imageUrl: "assets/images/settings/skybill.jpg",
      },
    },
    {
      type: "category",
      title: "Payments",
      hero: {
        mode: "titleArt",
        imageUrl: "assets/images/settings/payments.jpg",
      },
    },
    {
      type: "category",
      title: "Forgotten PIN?",
      hero: {
        mode: "titleArt",
        imageUrl: "assets/images/settings/forgotten-pin.jpg",
      },
    },
    {
      type: "category",
      title: "Parental Settings",
      hero: {
        mode: "titleArt",
        imageUrl: "assets/images/settings/parental-settings.jpg",
      },
    },
  ],
};

export default rail;
