import Rail from "../types/rail.type";
import { rail as railDims } from "../constants/dimensions";
import { shouldShowRailTitle } from ".";

const calculatePageOffsetY = (
    rails: Rail[],
    index: number,
    indices: { [index: number]: number }
) =>
    rails
      .slice(0, index)
      .reduce(
        (y, rail, i) => y +
          railDims.height(rail.template)! +
          railDims.marginTop(shouldShowRailTitle(rail, indices[i])),
        railDims.marginTop(true)
      );

export default calculatePageOffsetY;