import type Rail from "../../../types/rail.type";

const rail: Rail = {
    template: "tile-cover",
    title: "Under 5s",
    items: [
        {
            type: "cover",
            imageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/8550f3405367cca0d9a8db89227c6ef3/261/348/item.jpg",
            hero: {
                mode: "standard",
                title: "Fireman Sam",
                synopsis: "More adventures in Pontypandy with the nation's favourite emergency worker.",
                imageUrl: "http://images.metadata.sky.com/pd-image/79f9cc14-4f93-4d3d-8db2-da57e314284b/background/1428",
                logoUrl: "http://images.metadata.sky.com/pd-logo/skychb_cartoonito/365/205",
            },
            action: {
                type: "GO_TO_PAGE",
                pageType: "show",
                pageId: "genericKidsUpsell"
            }
        },
        {
            type: "cover",
            imageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/8550f34053d0db38fce201a3131e50bf/261/348/item.jpg",
            hero: {
                mode: "standard",
                title: "Bluey",
                synopsis: "Animated kids' series set in Brisbane, the series following a dog named Bluey, who is bursting with energy, imagination and curiosity.",
                imageUrl: "http://images.metadata.sky.com/pd-image/b4710b5c-8f2b-403b-8e63-7dc86331834a/background/1428",
                logoUrl: "http://images.metadata.sky.com/pd-logo/skychb_cbeebies/365/205",
            },
        },
        {
            type: "cover",
            imageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/8550f34053a29a55a7ad998bda102ab4/{width}/{height}/item.jpg",
            hero: {
                mode: "standard",
                title: "Pip and Posy",
                synopsis: "Come and join Pip and Posy, a mouse and a rabbit whose lives revolve around a wonderful world of play. New episodes available.",
                imageUrl: "http://images.metadata.sky.com/pd-image/82713ec1-2dda-4ce1-aa3b-27c0d374e54a/background/1428",
                logoUrl: "http://images.metadata.sky.com/pd-logo/skychb_skykids/365/205"
            },
            action: {
                type: "GO_TO_PAGE",
                pageType: "show",
                pageId: "genericKidsUpsell"
            }
        },

        {
            type: "cover",
            imageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/8550f340537e5a6e63b5e86a6d6e459e/261/348/item.jpg",
            hero: {
                mode: "standard",
                title: "Peppa Pig",
                synopsis: "Every day is an adventure for Peppa Pig! With her family and friends there's always something to smile about, and a whole world of things to discover!",
                imageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/788e2d1758e08f2c097bb080777ffbad/1428/803/hero.jpg",
                logoUrl: "http://images.metadata.sky.com/pd-logo/skychb_skykids/365/205"
            },
            action: {
                type: "GO_TO_PAGE",
                pageType: "show",
                pageId: "genericKidsUpsell"
            }
        },
        {
            type: "cover",
            imageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/8550f340536c12f8094ad61dfe68c027/261/348/item.jpg",
            hero: {
                mode: "standard",
                title: "Bing",
                synopsis: "Animated series based on the Bing Bunny books by Ted Dewan, exploring what life is like for three-year-old bunny Bing, his carer Flop, his friends and all the grown-ups in their lives.",
                imageUrl: " http://images.metadata.sky.com/pd-image/a2856d7f-2d1f-416c-808e-dc771e016166/background/1428",
                logoUrl: "http://images.metadata.sky.com/pd-logo/skychb_cbeebies/365/205"
            },

        },
        {
            type: "cover",
            imageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/8550f340537c155ec26f33662cbb8596/{width}/{height}/item.jpg",
            hero: {
                mode: "standard",
                title: "Go Buster",
                synopsis: "Join popular Little Baby Bum character Buster, a curious, friendly and eager-to-learn yellow bus, as he goes on a series of brand-new adventures.",
                imageUrl: " http://cdn.skyq.sky.com/qms/media/gbr/live/788e2d1758ce94a48b999496c0979e37/1428/803/hero.jpg",
                logoUrl: "http://images.metadata.sky.com/pd-logo/skychb_moonbug/365/205"
            },
            action: {
                type: "GO_TO_PAGE",
                pageType: "show",
                pageId: "genericKidsUpsell"
            }
        },
    ]
};

export default rail;