import { RailsStore } from "@ixd-group/common-stores";
import { Atom } from "@ixd-group/rx-utils";

import type Rail from "../../types/rail.type";

export const createStores = (isFocused$: Atom<boolean>) => {
    return {
        isFocused$,
        railsStore: new RailsStore<Rail>()
    };
}

export type Stores = ReturnType<typeof createStores>;
