import { startOfHour, addHours } from "date-fns";

import type Rail from "../../../types/rail.type";

const date = Date.now(),
    past = startOfHour(date).getTime(),
    future = addHours(past, 1).getTime();

const rail: Rail = {
    template: "tile-now-next",
    title: "TV Guide",
    items: [
        {
            type: "title",
            title: "TV Guide",
            actionText: "View all",
            hero: {
                mode: "standard",
                title: "TV Guide",
                synopsis: "Explore the shows you know and love.",
                imageUrl:
                    "https://images.metadata.sky.com/pd-image/b7059eb2-baff-4b2d-83fc-ca879b7740fe/background/{width}",
            },
        },
        {
            type: "nowAndNext",
            imageUrl: "assets/images/content/line_of_duty/tile.png",
            providerImageUrl: "assets/images/provider/bbc_iplayer.png",
            nowTitle: "Line of Duty",
            nowStart: past,
            nextStart: future,
            nextTitle: "Garden Rescue",
            progressPercent: 23,
            hero: {
                mode: "standard",
                title: "Line of Duty",
                synopsis:
                    "Episode 2. AC-12 crank up their investigation into Operation Trapdoor. Contains some upsetting scenes.",
                imageUrl:
                    "https://images.metadata.sky.com/pd-image/b7059eb2-baff-4b2d-83fc-ca879b7740fe/background/{width}",
                logoUrl: "assets/images/provider/bbc_iplayer.png",
            }
        },
        {
            type: "nowAndNext",
            imageUrl: "assets/images/content/motherland/tile.png",
            providerImageUrl: "assets/images/provider/bbc_iplayer.png",
            nowTitle: "Motherland",
            nowStart: past,
            nextStart: future,
            nextTitle: "Location Location",
            progressPercent: 45,
            hero: {
                mode: "standard",
                title: "Motherland",
                synopsis:
                    "Sitcom. A school fundraiser is not Julia's idea of an evening out, but somehow she finds herself heavily involved in order to impress a former colleague and new mum crush.",
                imageUrl:
                    "assets/images/content/motherland/hero.jpg",
                logoUrl: "assets/images/provider/bbc_iplayer.png",
            }
        },
        {
            type: "nowAndNext",
            imageUrl: "assets/images/content/below_deck/tile.png",
            providerImageUrl: "assets/images/provider/channel_4.png",
            nowTitle: "Below Deck",
            nowStart: past,
            nextStart: future,
            nextTitle: "Channel 4 News",
            progressPercent: 88,
            hero: {
                mode: "standard",
                title: "Below Deck",
                synopsis:
                    "Joao's ego gets him in trouble. Jack asks the crew to help him ask Aesha out.",
                imageUrl:
                    "https://images.metadata.sky.com/pd-image/578fc5f5-6368-4dd9-882f-082dcf47b9b2/background/{width}",
                logoUrl: "assets/images/provider/channel_4.png",
            }
        },
        {
            type: "nowAndNext",
            imageUrl: "assets/images/content/love_island/tile.png",
            providerImageUrl: "assets/images/provider/itv.png",
            nowTitle: "Love Island",
            nowStart: past,
            nextStart: future,
            nextTitle: "Coronation Street",
            progressPercent: 1,
            hero: {
                mode: "standard",
                title: "Love Island",
                synopsis:
                    "Reality show. Glamorous singles live in a beautiful villa under the watchful gaze of the audience at home, who have the power to decide who stays and who goes.",
                imageUrl:
                    "https://images.metadata.sky.com/pd-image/c73294ba-2847-4058-9648-e1c9e0ab8f66/background/{width}",
                logoUrl: "assets/images/provider/itv.png",
            }
        }
    ]
};

export default rail;