import React from "react";

import GlobalStyles from "./shared-components/GlobalStyles";
import Container from "./modules/Container";

const App: React.FC = () => {
  return (
    <>
      <GlobalStyles />
      <Container />
    </>
  );
}

export default App;
