import { ButtonHandler, ButtonListener, PubSub } from "@ixd-group/common-services";

export const createServices = (
    buttonHandler: ButtonHandler | undefined,
    pubSub: PubSub<{
        moveLens: {
            direction: "left" | "right",
            tileWidth?: number
        }
    }>
) => {
    return {
        buttonHandler: buttonHandler || new ButtonHandler(),
        buttonListener: new ButtonListener(),
        pubSub
    };
}

export type Services = ReturnType<typeof createServices>;
