import React from "react";
import {
  Hero as LlamaHero,
  HeroProps as LlamaHeroProps,
} from "@ixd-group/llama-components";

import Button from "../Button";
import type HeroType from "../../../../types/hero.type";

export type HeroProps = HeroType & {
  isFocused: boolean;
  shift: LlamaHeroProps["shift"];
};

const Hero: React.FC<HeroProps> = ({ isFocused, ...hero }) => (
  <LlamaHero {...hero}>
    {hero.buttons?.map((button, index) => (
      <Button isFocused={isFocused} key={index} {...button} />
    ))}
  </LlamaHero>
);

export default Hero;
