import { startOfHour } from "date-fns";

import type Rail from "../../../types/rail.type";

const date = Date.now(),
    past = startOfHour(date).getTime();

const rail: Rail = {
    template: "tile-now-next",
    title: "TV Guide - Now and Next",
    items: [
        {
            type: "title",
            title: "TV Guide - Now and Next",
            actionText: "View all",
            hero: {
                mode: "standard",
                title: "TV Guide - Now and Next",
                imageUrl: "assets/images/content/live_london_spirit_v_trent_rockets/hero.jpg",
            },
        },
        {
            type: "nowAndNext",
            imageUrl: "assets/images/content/live_manchester_originals_v_northern_superchargers/tile.png",
            providerImageUrl: "assets/images/provider/sky_sports_hundred_hd.png",
            nowTitle: "Live: Manchester Originals v Northern Superchargers",
            nowStart: past,
            progressPercent: 50,
            hero: {
                mode: "standard",
                synopsis: "Manchester Originals v Northern Superchargers",
                title: "The Hundred",
                imageUrl: "assets/images/content/live_london_spirit_v_trent_rockets/hero.jpg",
                logoUrl: "assets/images/provider/sky_sports_hundred_hd.png",
            },
            action: { type: "GO_TO_PAGE", pageType: "show", pageId: "genericSportsUpsell" }
        },
        {
            type: "nowAndNext",
            imageUrl: "https://myriad.merlin.xfinity.eu/select/image?entityId=6150138349623314112&width=464&height=261&rule=Sky_Tile&partnerIdentifier=sky-uk",
            providerImageUrl: "assets/images/provider/eurosport_2_hd.png",
            nowTitle: "GolfTV Weekly",
            nowStart: past,
            progressPercent: 50,
            hero: {
                mode: "standard",
                title: "GolfTV Weekly",
                synopsis: "A weekly show featuring exclusive interviews and action from the latest events in the world of golf.",
                imageUrl: "https://myriad.merlin.xfinity.eu/select/image?entityId=6150138349623314112&width=1920&height=1080&rule=Sky_Background&partnerIdentifier=sky-uk",
                logoUrl: "assets/images/provider/eurosport_2_hd.png",
            },
            action: { type: "GO_TO_PAGE", pageType: "show", pageId: "eurosportGolfTV" }
        },
        {
            type: "nowAndNext",
            imageUrl: "https://images.metadata.sky.com/pd-image/704d8f91-d379-4ac0-8160-7de820d8343a/16-9/{width}",
            providerImageUrl: "assets/images/provider/sky_sports_arena.png",
            nowTitle: "British Lions",
            nowStart: past,
            progressPercent: 50,
            hero: {
                mode: "standard",
                title: "British Lions",
                imageUrl: "https://images.metadata.sky.com/pd-image/704d8f91-d379-4ac0-8160-7de820d8343a/16-9/{width}",
                logoUrl: "assets/images/provider/sky_sports_arena.png",
            },
            action: { type: "GO_TO_PAGE", pageType: "show", pageId: "genericSportsUpsell" }
        },
        {
            type: "nowAndNext",
            imageUrl: "https://images.metadata.sky.com/pd-image/d39b2b37-555a-4b7d-8785-78da116da713/16-9/{width}",
            providerImageUrl: "assets/images/provider/itv.png",
            nowTitle: "Race Day",
            nowStart: past,
            progressPercent: 50,
            hero: {
                mode: "standard",
                title: "Race Day",
                imageUrl: "https://images.metadata.sky.com/pd-image/d39b2b37-555a-4b7d-8785-78da116da713/background/{width}",
                logoUrl: "assets/images/provider/itv.png",
            },
            action: { type: "GO_TO_PAGE", pageType: "show", pageId: "itvHorseRacing" }
        }
    ]
};

export default rail;