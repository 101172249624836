import { ButtonHandler, PubSub } from "@ixd-group/common-services";

export const createServices = () => {
    return {
        buttonHandler: new ButtonHandler(),
        pubSub: new PubSub<{
            moveLens: {
                direction: "left" | "right",
                tileWidth?: number
            }
        }>()
    };
}

export type Services = ReturnType<typeof createServices>;
