import { ButtonHandler } from "@ixd-group/common-services";
import { useButtons } from "@ixd-group/react-utils";
import { useState } from "react";

export type Props = {
  buttonHandler: ButtonHandler;
  upsellImageUrl?: string;
  confirmationImageUrl?: string;
  showpageImageUrl: string;
  isSubscribed?: boolean;
  onSubscribe: () => void;
};

const Container = ({
  buttonHandler,
  upsellImageUrl,
  confirmationImageUrl,
  showpageImageUrl,
  isSubscribed,
  onSubscribe,
}: Props) => {
  const [isOnConfirmPage, setIsOnConfirmPage] = useState(false);

  const imageUrl = !isSubscribed
    ? upsellImageUrl
    : isOnConfirmPage
    ? confirmationImageUrl
    : showpageImageUrl;

  function handleNext() {
    if (isSubscribed) {
      if (isOnConfirmPage) {
        setIsOnConfirmPage(false);
      } else {
        // do nothing
      }
    } else {
      onSubscribe();
      setIsOnConfirmPage(true);
    }
  }

  useButtons(["show"], buttonHandler, true, {
    SELECT: () => handleNext(),
  });

  return <img src={imageUrl} alt="Show page" />;
};

export default Container;
