import React from "react";
import { ModuleContext, useRxState } from "@ixd-group/react-utils";

import HeaderController from "./components/Header/HeaderController";
import HeroController from "./components/Hero/HeroController";
import PageController from "./components/Page/PageController";
import { Container as StyledContainer } from "./index.styles";
import { useContainerLogic } from "./logic";

const Container: React.FC = () => {
  const { scale, services, stores } = useContainerLogic();

  const currentPage = useRxState(stores.currentPage$),
    // TODO: a less hacky way of saying if in kids section
    inKidsSection = ["skyKids", "dreamflight"].includes(currentPage.id),
    backgroundImage = inKidsSection
      ? "./assets/images/backgrounds/themebackgroundnologo.jpeg"
      : "./assets/images/backgrounds/llama-wallpaper.jpg",
    logo = inKidsSection
      ? "./assets/images/brands/skyKids/sky-kids-logo.png"
      : "./assets/images/brands/sky/sky-logo.png";

  return (
    <ModuleContext.Provider value={{ services, stores }}>
      <StyledContainer
        scale={scale}
        style={{
          backgroundColor: "#0C1B7F",
          backgroundImage: `url("${backgroundImage}")`,
        }}
      >
        <HeaderController logoUrl={logo} />
        <HeroController />
        <PageController />
      </StyledContainer>
    </ModuleContext.Provider>
  );
};

export default Container;
