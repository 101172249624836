import type Rail from "../../../types/rail.type";

const rail: Rail = {
    template: "tile-switcher",
    title: "Top 10 this week",
    items: [
        {
            type: "switcher",
            imageUrl: "assets/images/content/midway/tile.png",
            hero: {
                mode: "standard",
                imageUrl: "https://images.metadata.sky.com/pd-image/752a41df-dd55-4003-908e-4011d493d6c2/background/{width}",
                logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_skycinema/{width}/{height}",
                title: "Midway",
                synopsis: "On Dec. 7, 1941, Japanese forces launch a devastating attack on Pearl Harbor, the U.S. naval base in Hawaii.",
                metadata: "2019    2h 18m"
            }
        },
        {
            type: "switcher",
            imageUrl: "assets/images/content/james_and_the_giant_peach/tile.png",
            hero: {
                mode: "standard",
                imageUrl: "https://images.metadata.sky.com/pd-image/a97991f2-d702-44be-9e4b-a2da3194525e/background/{width}",
                logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_skycinema/{width}/{height}",
                title: "James and the Giant Peach",
                synopsis: "An orphaned boy drops magical crocodile tongues which later grow into a never-ending peach tree. He finds his new family which are six insects that help him on his adventure.",
            }
        },
        {
            type: "switcher",
            imageUrl: "assets/images/content/lie_with_me/tile.png",
            hero: {
                mode: "standard",
                imageUrl: "https://images.metadata.sky.com/pd-image/56da0d15-6cb0-442e-a9b6-2f21416cd17b/background/{width}",
                logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_channel5/{width}/{height}",
                title: "Lie with Me",
                synopsis: "A British woman and her husband seek a fresh start in Australia after infidelity in their marriage. The couple hire a young nanny, but she isn't the innocent stranger she pretends to be.",
            }
        },
        {
            type: "switcher",
            imageUrl: "assets/images/content/manifest/tile.png",
            hero: {
                mode: "standard",
                imageUrl: "https://images.metadata.sky.com/pd-image/77aa7cc1-8dac-4b01-8989-7766afe18059/background/{width}",
                logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_skyone/{width}/{height}",
                title: "Manifest",
                synopsis: "After being presumed dead, 191 passengers of the Montego Air Flight 828 try to reintegrate into the society. However, disturbing realities come to light which unsettle them.",
            }
        }
    ]
};

export default rail;
