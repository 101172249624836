import { VideoPageData } from "../../../types/page.type";
import {mediaData} from "../fanPages/media";

export const makingOfDune: VideoPageData = {
  type: "video",
  id: "makingOfDune",
  video: "https://youtu.be/UvtiiuyO-gU",
  image: "assets/images/fanPages/tiles/MakingOfDune.jpg",
};

export const duneBreakdown: VideoPageData = {
  type: "video",
  id: "duneBreakdown",
  video: "https://youtu.be/1kE9LOhEjMQ",
  image: "assets/images/fanPages/tiles/dune_vid_02.jpg",
};

export const politicsInDune: VideoPageData = {
  type: "video",
  id: "politicsInDune",
  image: "assets/images/fanPages/tiles/politics_on_dune.jpg",
  video: "https://youtu.be/Cli2PU03u3A",
};

export const bayernClip01: VideoPageData = {
  type: "video",
  id: "bayernClip01",
  image: mediaData.Bayern[0].image,
  video: mediaData.Bayern[0].video,
};

export const bayernClip02: VideoPageData = {
  type: "video",
  id: "bayernClip02",
  image: mediaData.Bayern[1].image,
  video: mediaData.Bayern[1].video,
};

export const bayernClip03: VideoPageData = {
  type: "video",
  id: "bayernClip03",
  image: mediaData.Bayern[2].image,
  video: mediaData.Bayern[2].video,
};

export const bayernClip04: VideoPageData = {
  type: "video",
  id: "bayernClip04",
  image: mediaData.Bayern[3].image,
  video: mediaData.Bayern[3].video,
}

export const greysClip01: VideoPageData = {
  type: "video",
  id: "greysClip01",
  image: mediaData.Greys[0].image,
  video: mediaData.Greys[0].video,
}

export const greysClip02: VideoPageData = {
  type: "video",
  id: "greysClip02",
  image: mediaData.Greys[1].image,
  video: mediaData.Greys[1].video,
}

export const greysClip03: VideoPageData = {
  type: "video",
  id: "greysClip03",
  image: mediaData.Greys[2].image,
  video: mediaData.Greys[2].video,
}

export const greysClip04: VideoPageData = {
  type: "video",
  id: "greysClip04",
  image: mediaData.Greys[3].image,
  video: mediaData.Greys[3].video,
}
