import React from "react";
import {
  useButtons,
  useRxState,
  useServices,
  useStores,
} from "@ixd-group/react-utils";

import { getInitialFocusArea } from "../../../../../utilities";
import { getPage } from "../../../../../data/pages";
import { Services } from "../../../services";
import { Stores } from "../../../stores";
import Hero from "../";

const HeroController: React.FC = () => {
  const { buttonHandler, pubSub } = useServices<Services>();
  const { currentPage$, focusArea$, heroStore, historyStore } =
    useStores<Stores>();

  const [hero, isFocused, page] = useRxState([
    heroStore.hero$,
    focusArea$.map((area) => area === "hero"),
    currentPage$,
  ]);

  useButtons(["hero"], buttonHandler, isFocused, {
    SELECT: () => {
      const action = hero?.buttons?.[0]?.action;
      if (action && action.type === "GO_TO_PAGE") {
        historyStore.push(page);

        try {
          const page = getPage(action.pageId, action.pageType);
          focusArea$.set(getInitialFocusArea(page.type));
          currentPage$.set(page);
        } catch {
          historyStore.pop();
        }
      }
    },
  });

  if (
    !hero ||
    hero.mode === "none" ||
    page.type === "mock" ||
    page.type === "show"
  ) {
    return null;
  }

  return (
    <Hero
      {...hero}
      isFocused={isFocused}
      shift={pubSub.observable("moveLens")}
    />
  );
};

export default HeroController;
