import type { ShowPage } from "../../../../types/page.type";

const page: ShowPage = {
  type: "show",
  id: "eurosportGolfTV",
  // needsSubscription: "sports",
  showpageImageUrl: "assets/images/mocks/eurosportGolfTVLive.png",
  upsellImageUrl: "assets/images/mocks/sports-upsell.png",
  confirmationImageUrl: "assets/images/mocks/confirm-sky-sports.jpg"
};

export default page;