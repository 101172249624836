import { Clock, HeroStore, HistoryStore } from "@ixd-group/common-stores";
import { atom, combine } from "@ixd-group/rx-utils";

import type FocusArea from "../../types/focus-area.type";
import type Hero from "../../types/hero.type";
import type HistoryItem from "../../types/history-item.type";
import type Page from "../../types/page.type";
import { getPage } from "../../data/pages";

export const createStores = () => {
  const defaultPage = getPage("home", "category"),
    currentPage$ = atom<Page>(defaultPage),
    isSubscribedKids$ = atom(false),
    isSubscribedSports$ = atom(false),
    isSubscribed$ = combine([currentPage$, isSubscribedKids$, isSubscribedSports$],
      ([currentPage, isSubscribedKids, isSubscribedSports]) => {
        if (currentPage.type === "show") {
          switch (currentPage.needsSubscription) {
            case "kids":
              return isSubscribedKids;
            case "sports":
              return isSubscribedSports;
            default:
              return true;
          }
        } else {
          return true;
        }
      });
  return {
    currentPage$,
    clock: new Clock(),
    focusArea$: atom<FocusArea>("browse"),
    heroStore: new HeroStore<Hero>(),
    historyStore: new HistoryStore<HistoryItem>(),
    homeId$: atom<Page["id"]>("home"),
    isSubscribedKids$,
    isSubscribedSports$,
    isSubscribed$
  };
};

export type Stores = ReturnType<typeof createStores>;
