import React from "react";
import {
  Button,
  CategoryTile,
  CoverTile,
  LandscapeTile,
  NowAndNextTile,
  SquareTile,
  SwitcherTile,
  TitleTile,
} from "@ixd-group/llama-components";

import type { default as TileType } from "../../../../types/tile.type";

export type TileProps = {
  index?: number;
  isFocused?: boolean;
  tile: TileType;
};

const Tile: React.FC<TileProps> = ({ index, isFocused = false, tile }) => {
  switch (tile.type) {
    case "button":
      return <Button {...tile} focused={isFocused} />;
    case "category":
      return <CategoryTile {...tile} focused={isFocused} index={index} />;
    case "cover":
      return (
        <CoverTile
          title={tile.title || ""}
          imageUrl={tile.imageUrl}
          focused={isFocused}
        />
      );
    case "landscape":
      return <LandscapeTile {...tile} focused={isFocused} />;
    case "nowAndNext":
      return <NowAndNextTile {...tile} focused={isFocused} />;
    case "square":
      return <SquareTile {...tile} focused={isFocused} />;
    case "switcher":
      return <SwitcherTile {...tile} focused={isFocused} />;
    case "title":
      return <TitleTile {...tile} focused={isFocused} />;
    default:
      return null;
  }
};

export default Tile;
