import type Page from "../../types/page.type";

import home from "./home";
import skySports from "./skySports";
import skyKids from "./skyKids";
import dreamflight from "./showPagesKids/dreamflight";
import liveGoodMorningFootball from "./showPagesSport/liveGoodMorningFootball";
import liveICCSriNam from "./showPagesSport/liveICCSriNam";
import liveICCIreNed from "./showPagesSport/liveICCIreNed";
import fourOclockClub from "./showPagesKids/fourOclockClub";
import topsyAndTim from "./showPagesKids/topsyAndTim";
import eurosportGolfTV from "./showPagesSport/eurosportGolfTV";
import eurosportSnooker from "./showPagesSport/eurosportSnooker";
import genericSportsUpsell from "./showPagesSport/genericSportsUpsell";
import genericKidsUpsell from "./showPagesKids/genericKidsUpsell";
import itvHorseRacing from "./showPagesSport/itvHorseRacing";
import documentJohnMcEnroe from "./showPagesSport/documentJohnMcEnroe";
import documentTheUnitedWay from "./showPagesSport/documentTheUnitedWay";
import duneFanPage from "./fanPages/duneFanPage";
import bayernFanPage from "./fanPages/bayernFanPage";
import greysAnatomyFanPage from "./fanPages/greysAnatomyFanPage";
import {
  makingOfDune,
  duneBreakdown,
  politicsInDune,
  bayernClip01,
  bayernClip02,
  bayernClip03,
  bayernClip04,
  greysClip01,
  greysClip02,
  greysClip03,
  greysClip04,
} from "./videoPages/factory";

const pages = {
  home,
  skySports,
  skyKids,
  dreamflight,
  liveGoodMorningFootball,
  liveICCIreNed,
  liveICCSriNam,
  fourOclockClub,
  topsyAndTim,
  eurosportGolfTV,
  eurosportSnooker,
  itvHorseRacing,
  genericSportsUpsell,
  genericKidsUpsell,
  documentJohnMcEnroe,
  documentTheUnitedWay,
  duneFanPage,
  bayernFanPage,
  greysAnatomyFanPage,

  // Videos
  makingOfDune,
  duneBreakdown,
  politicsInDune,
  bayernClip01,
  bayernClip02,
  bayernClip03,
  bayernClip04,
  greysClip01,
  greysClip02,
  greysClip03,
  greysClip04,
};

export const getPage = <T extends Page["type"]>(id: Page["id"], type: T) => {
  const page = pages[id];
  if (!page) {
    throw new Error(`Could not find page with ID "${id}"`);
  } else if (page.type !== type) {
    throw new Error(`Expected page with ID "${id}" to be of type "${type}"`);
  }
  return page;
};
