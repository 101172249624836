import type Rail from "../../../types/rail.type";

const rail: Rail = {
    template: "tile-square",
    title: "Podcasts",
    items: [
        {
            type: "square",
            imageUrl: "assets/images/podcasts/that_peter_crouch_euros_pod.png",
            hero: {
                mode: "standard",
                title: "That Peter Crouch Euros Pod",
                synopsis: "",
                imageUrl: "",
            },
        },
        {
            type: "square",
            imageUrl: "assets/images/podcasts/match_of_the_day_top_10.png",
            hero: {
                mode: "standard",
                title: "Match of the Day Top 10",
                synopsis: "",
                imageUrl: "",
            },
        },
        {
            type: "square",
            imageUrl: "assets/images/podcasts/sports_strangest_crimes.png",
            hero: {
                mode: "standard",
                title: "Sport's Strangest Crimes",
                synopsis: "",
                imageUrl: "",
            },
        },
        {
            type: "square",
            imageUrl: "assets/images/podcasts/sky_sports_cricket_podcast.png",
            hero: {
                mode: "standard",
                title: "Sky Sports Cricket Podcast",
                synopsis: "",
                imageUrl: "",
            },
        },
        {
            type: "square",
            imageUrl: "assets/images/podcasts/no_passion_no_point.png",
            hero: {
                mode: "standard",
                title: "No Passion No Point",
                synopsis: "",
                imageUrl: "",
            },
        },
        {
            type: "square",
            imageUrl: "assets/images/podcasts/pitch_to_post_preview.png",
            hero: {
                mode: "standard",
                title: "Pitch to Post Preview",
                synopsis: "",
                imageUrl: "",
            },
        },
        {
            type: "square",
            imageUrl: "assets/images/podcasts/test_match_special.png",
            hero: {
                mode: "standard",
                title: "Test Match Special",
                synopsis: "",
                imageUrl: "",
            },
        },
    ]
};

export default rail;