import type { CategoryPage } from "../../../types/page.type";

import liveSportThisWeek from "./liveSportThisWeek";
import latestSkySports from "./latestSkySports";
import sportsOnNow from "./sportsOnNow";
import browseBySport from "./browseBySport";
import podcasts from "./podcasts";
import topTen from "./topTen";
import latestHighlist from "./latestHighlist";
import latestSkyFootbalNews from "./latestSkyFootbalNews";


export default {
    id: "skySports",
    rails: [
        liveSportThisWeek,
        latestSkySports,
        sportsOnNow,
        topTen,
        latestHighlist,
        browseBySport,
        latestSkyFootbalNews,
        podcasts,

    ],
    title: "Sports",
    type: "category"
} as CategoryPage;
