import type { FanPageData } from "../../../types/page.type";
import { mediaData } from "./media";
import { DuneExtras } from "./extras";

const page: FanPageData = {
  type: "fan",
  id: "duneFanPage",
  showpageImageUrl: "assets/images/fanPages/dune_background.png",
  media: mediaData.Dune,
  firstPageWidgets: "assets/images/fanPages/dune_widgets_01.png",
  extras: DuneExtras,
};

export default page;
