import type Rail from "../../../types/rail.type";

const rail: Rail = {
  template: "tile-landscape",
  title: "Football news",
  items: [
    {
      type: "landscape",
      title: "Rodgers: Striker rotation is working",
      imageUrl: "http://img.skysports.com/21/09/456x257/skysports-jamie-vardy-leicester-city_5525021.jpg?20210925165349",
      hero: {
        mode: "standard",
        title: "Rodgers: Striker rotation is working",
        synopsis: "Leicester manager Brendan Rodgers says their management of the club's strikers has helped them on the pitch in recent weeks.",
        imageUrl: "https://cdn.skyq.sky.com/mashup/images/uk/condatis/sports/NewsPL-HL.jpg",
      }
    },
    {
      type: "landscape",
      title: "'Players starting to believe in the process'",
      imageUrl: "http://img.skysports.com/21/10/456x257/skysports-ange-postecoglou_5550704.jpg?20211018145926",
      hero: {
        mode: "standard",
        title: "'Players starting to believe in the process'",
        synopsis: "Graeme Jones has confirmed that Newcastle's new owners have asked him to lead the team for their games against Crystal Palace and Chelsea.",
        imageUrl: "https://cdn.skyq.sky.com/mashup/images/uk/condatis/sports/NewsPL-HL.jpg",
      }
    },
    {
      type: "landscape",
      title: "Calvert-Lewin to miss four to five weeks",
      imageUrl: "http://img.skysports.com/21/08/456x257/skysports-calvert-lewin-everton_5493555.jpg?20210828172955",
      hero: {
        mode: "standard",
        title: "Calvert-Lewin to miss four to five weeks",
        synopsis: "Everton manager Rafael Benitez says striker Dominic Calvert-Lewin will be out of action for over a month after suffering a setback in his return from a thigh injury.",
        imageUrl: "https://cdn.skyq.sky.com/mashup/images/uk/condatis/sports/NewsPL-HL.jpg",
      }
    },
    {
      type: "landscape",
      title: "Ole: Fernandes a doubt for Liverpool game",
      imageUrl: "http://img.skysports.com/21/09/456x257/skysports-bruno-fernandes-manchester-united_5524734.jpg?20210925143949",
      hero: {
        mode: "standard",
        title: "Ole: Fernandes a doubt for Liverpool game",
        synopsis: "Manchester United manager Ole Gunnar Solskjaer has confirmed that Bruno Fernandes is a doubt for their game against Liverpool on Super Sunday.",
        imageUrl: "https://cdn.skyq.sky.com/mashup/images/uk/condatis/sports/NewsPL-HL.jpg",
      }
    },
    {
      type: "landscape",
      title: "How do Salah and Ronaldo compare?",
      imageUrl: "http://img.skysports.com/21/10/456x257/skysports-salah-ronaldo_5555421.jpg?20211022134752",
      hero: {
        mode: "standard",
        title: "How do Salah and Ronaldo compare?",
        synopsis: "Liverpool manager Jurgen Klopp says it's hard to compare Mohamed Salah and Cristiano Ronaldo ahead of their meeting at Old Trafford on Sunday.",
        imageUrl: "https://cdn.skyq.sky.com/mashup/images/uk/condatis/sports/NewsPL-HL.jpg",
      }
    },
  ]
};

export default rail;
