import type Rail from "../../../types/rail.type";

const rail: Rail = {
    template: "tile-switcher",
    title: "Top 10",
    items: [
        {
            type: "switcher",
            // tag: "Top 10 this week",
            title: "Black History Month",
            imageUrl: " http://cdn.skyq.sky.com/qms/media/gbr/live/8550f3405300d015243de9b2063d6caa/726/410/item.jpg",
            providerImageUrl:
                " http://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
            hero: {
                mode: "standard",
                // titleImageUrl: "assets/images/content/rob_and_romesh_vs/title.png",
                imageUrl: " http://cdn.skyq.sky.com/qms/media/gbr/live/788e2d175812cf8b4dbef6bce2058b8e/1428/803/hero.jpg",
                logoUrl: " http://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
                title: "Black History Month",
                synopsis: "Celebrate Black History Month with these riveting documentaries on history-making Black athletes and iconic sporting moments, as well as the ongoing fight against racial injustice",
                metadata: "2019    3 series"
            },
            action: { type: "GO_TO_PAGE", pageType: "show", pageId: "genericSportsUpsell" }
        },
        {
            type: "switcher",
            // tag: "Top pick for you",
            title: "John McEnroe: In The Realm of Perfection",
            imageUrl: " http://images.metadata.sky.com/pd-image/9c7c97e9-66d3-48ef-b109-9e597fbb0cf0/16-9/726?providername=skydocumentaries",
            providerImageUrl:
                "http://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/365/205",
            hero: {
                mode: "standard",
                // titleImageUrl: "assets/images/content/outer_banks/title.png",
                imageUrl: "http://images.metadata.sky.com/pd-image/9c7c97e9-66d3-48ef-b109-9e597fbb0cf0/background/1428",
                logoUrl: "http://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/365/205",
                title: "John McEnroe: In The Realm of Perfection",
                synopsis: "...Of Perfection. Documentary following tennis icon John McEnroe at the 1984 French Open final, showing what made him so captivating.",
            },
            action: { type: "GO_TO_PAGE", pageType: "show", pageId: "documentJohnMcEnroe" }
        },
        {
            type: "switcher",
            // tag: "Top pick for you",
            title: "Indian Wells 2021",
            imageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/8550f34053cc64fbe85455d77d1373a9/726/410/item.jpg",
            providerImageUrl:
                " http://cdn.skyq.sky.com/qms/media/gbr/live/a2232ab473f396cf1bc426a683250652/365/205/logo.png",
            hero: {
                mode: "standard",
                // titleImageUrl: "assets/images/content/the_nevers/title.png",
                imageUrl: " http://cdn.skyq.sky.com/qms/media/gbr/live/788e2d1758cc64fbe85455d77d1373a9/1428/803/hero.jpg",
                logoUrl: " http://cdn.skyq.sky.com/qms/media/gbr/live/a2232ab473f396cf1bc426a683250652/365/205/logo.png",
                title: "Indian Wells 2021",
                synopsis: "Watch the latest action from the 2021 BNP Paribas Open exclusively on Prime Video.",
            },
            // action: { type: "GO_TO_PAGE", pageType: "show", pageId: "genericSportsUpsell" }
        },
        {
            type: "switcher",
            imageUrl: " https://myriad.merlin.xfinity.eu/select/image?entityId=4611934568150449112&width=464&height=261&rule=Sky_Tile&partnerIdentifier=sky-uk",
            providerImageUrl: "https://myriad.merlin.xfinity.eu/select/logo?entityId=5605722544178533105&width=368&height=207&rule=Sky_Logo_Padded&trim=false&partnerIdentifier=sky-uk",
            title: "Live Snooker: Northern Ireland Open",
            hero: {
                mode: "standard",
                title: "Live Snooker: Northern Ireland Open",
                synopsis: "Live coverage of matches in round three of the 2021 Northern Ireland Open at ...",
                imageUrl: "https://myriad.merlin.xfinity.eu/select/image?entityId=4611934568150449112&width=1920&height=1080&rule=Sky_Background&partnerIdentifier=sky-uk",
                logoUrl: "https://myriad.merlin.xfinity.eu/select/logo?entityId=5605722544178533105&width=368&height=207&rule=Sky_Logo_Padded&trim=false&partnerIdentifier=sky-uk",
            },
            action: { type: "GO_TO_PAGE", pageType: "show", pageId: "eurosportSnooker" }
        },
        {
            type: "switcher",
            // tag: "Top pick for you",
            title: "NFL This Week",
            imageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/8550f3405319f2fd4749cd5cc3eb0ec4/726/410/item.jpg",
            providerImageUrl:
                "http://images.metadata.sky.com/pd-logo/skychb_skyspnfl/365/205",
            hero: {
                mode: "standard",
                // titleImageUrl: "assets/images/content/the_crown/title.png",
                imageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/788e2d175879a92ad751d2c26adb2bc5/1428/803/hero.jpg",
                logoUrl: "http://images.metadata.sky.com/pd-logo/skychb_skyspnfl/365/205",
                title: "NFL This Week",
                synopsis: "All the latest content from the National Football League, including round-up shows, highlights, documentaries and features.",
                metadata: "2016    4 seasons"
            },
            action: { type: "GO_TO_PAGE", pageType: "show", pageId: "genericSportsUpsell" }
        },
        {
            type: "switcher",
            // tag: "Top pick for you",
            title: "Lewis In The Cinema: 2020 Turkey        ",
            imageUrl: "http://images.metadata.sky.com/pd-image/5ae9db01-963f-43df-be17-fc242e7bb478/16-9/726?providername=skyspf1",
            providerImageUrl:
                "http://images.metadata.sky.com/pd-logo/skychb_skyspf1/365/205",
            hero: {
                mode: "standard",
                // titleImageUrl: "assets/images/content/the_dig/title.png",
                imageUrl: " http://images.metadata.sky.com/pd-image/5ae9db01-963f-43df-be17-fc242e7bb478/background/1428",
                logoUrl: "http://images.metadata.sky.com/pd-logo/skychb_skyspf1/365/205",
                title: "Lewis In The Cinema: 2020 Turkey        ",
                synopsis: "Martin Brundle is with Mercedes' Lewis Hamilton in the Secret Cinema again, this time to look back on his race win in Turkey 2020, which made him a seven-time World Champion.",
            },
            action: { type: "GO_TO_PAGE", pageType: "show", pageId: "genericSportsUpsell" }
        },

    ]
};

export default rail;
