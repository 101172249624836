import { useEffect, useMemo, useState } from "react";
import { useButtons, useRxState, useSubscribe } from "@ixd-group/react-utils";

import { screen } from "../../constants/dimensions";
import { getPage } from "../../data/pages";
import { getInitialFocusArea, getWindowSize } from "../../utilities";
import { createServices } from "./services";
import { createStores } from "./stores";

export const useContainerLogic = () => {
    const services = useMemo(createServices, []);
    const stores = useMemo(createStores, []);
    // useSubscribe(stores.historyStore.last$, (value) => console.log({ historypage: value }))
    // useSubscribe(stores.currentPage$, (value) => console.log({ currentpage: value }))
    const [page, previousPage] = useRxState([
        stores.currentPage$,
        stores.historyStore.last$
    ]);
    useSubscribe(stores.focusArea$, (focusArea) => {
        if (focusArea === "hero" && "hero" in page) {
            stores.heroStore.set(page.hero);
        }
    });
    useButtons([], services.buttonHandler, true, {
        BACK: () => {
            if (previousPage) {
                stores.focusArea$.set(getInitialFocusArea(previousPage.type));
                stores.currentPage$.set(previousPage);
                stores.historyStore.pop();
            }
        },
        DOWN: () => {
            stores.focusArea$.set("browse");
        },
        HOME: () => {
            stores.historyStore.push(page);
            const home = getPage(stores.homeId$.get(), "category");
            stores.focusArea$.set(getInitialFocusArea(home.type));
            stores.currentPage$.set(home);
        },
        UP: () => {
            if ("hero" in page) {
                stores.focusArea$.set("hero");
            }
        }
    });

    /* Auto-scale the window */
    const [scale, setScale] = useState(1);
    useEffect(() => {
        const onResize = () => {
            const { height, width } = getWindowSize();
            setScale(Math.min(width / screen.width, height / screen.height));
        };
        window.addEventListener("resize", onResize);
        onResize();
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, [setScale]);

    return { scale, services, stores };
};
