import type Rail from "../../../types/rail.type";

const rail: Rail = {
  template: "tile-landscape",
  title: "Live Sport this week",
  items: [
    {
      type: "landscape",
      title: "Live: Good Morning Football",
      imageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/8550f34053c99a37342139840f6f149d/{width}/{height}/item.jpg",

      hero: {
        mode: "standard",
        title: "Live: Good Morning Football",
        synopsis: "The morning show destination for NFL fans offering a discussion of the latest news and talking points from around the league.",
        imageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/788e2d17583b5e40774cdee884ff3d86/{width}/{height}/hero.jpg",
        titleImageUrl: 'http://cdn.skyq.sky.com/qms/media/gbr/live/199ee2662bec4e742d17b327f4572c3e/herotitle.png',
        logoUrl: "assets/images/provider/sky_sports_football.png",
      },
      providerImageUrl: "assets/images/provider/sky_sports_football.png",
      action: {
        type: "GO_TO_PAGE",
        pageType: "show",
        pageId: "liveGoodMorningFootball"
      }
    },
    {
      type: "landscape",
      title: "Live ICC T20 World Cup: SL v NAM",
      imageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/8550f340532cd4f3c9b36f14120f2cda/456/257/item.jpg",

      hero: {
        mode: "standard",
        title: "Live ICC T20 World Cup: SL v NAM",
        synopsis: "The opening round of the ICC T20 World Cup as Sri Lanka meet Namibia in Abu Dhabi in Group A. This is the first game of the tournament for both teams.",
        imageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/788e2d175811069f21b82975b766d343/1428/803/hero.jpg",
        logoUrl: "http://images.metadata.sky.com/pd-logo/skychb_4090skyspmixhd/365/205",
        titleImageUrl: " http://cdn.skyq.sky.com/qms/media/gbr/live/199ee2662be010af7cc7da9169b432ed/herotitle.png"

      },
      providerImageUrl: "http://images.metadata.sky.com/pd-logo/skychb_4090skyspmixhd/365/205",
      action: {
        type: "GO_TO_PAGE",
        pageType: "show",
        pageId: "liveICCSriNam"
      }
    },
    {
      type: "landscape",
      title: "Live: The Football Show",
      imageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/8550f340539216b4bafe9ec040111f6a/456/257/item.jpg",

      hero: {
        mode: "standard",
        title: "Live: The Football Show",
        synopsis: "A look at all the latest news from around the world of football, with guests and pundits joining to discuss it all - as well as the wider picture in the beautiful game.",
        imageUrl: "http://images.metadata.sky.com/pd-image/33eee006-6c68-4b75-80d0-a716d6e9c6ba/background/1428",
        logoUrl: "http://images.metadata.sky.com/pd-logo/skychb_4010skyspplhd/365/205",
        titleImageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/199ee2662bc8b20692e5cd4b042750f2/herotitle.png"
      },
      providerImageUrl: "http://images.metadata.sky.com/pd-logo/skychb_4010skyspplhd/365/205",
      action: {
        type: "GO_TO_PAGE",
        pageType: "show",
        pageId: "genericSportsUpsell"
      }
    },
    {
      type: "landscape",
      title: "Rugby League: France v England",
      imageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/8550f34053b55f7af45521dc1db9c611/456/257/item.jpg",

      hero: {
        mode: "standard",
        title: "Rugby League: France v England",
        synopsis: "Live coverage from the Stade Gilbert Brutus in Perpignan, where England's men take on France in a key game for both teams in the build-up to next year's World Cup. ",
        imageUrl: "https://myriad.merlin.xfinity.eu/select/image?entityId=6087078416961217112&width=1920&height=1080&rule=Sky_Background&partnerIdentifier=sky-uk",
        logoUrl: "https://myriad.merlin.xfinity.eu/select/logo?entityId=5224666563250046117&width=368&height=207&rule=Sky_Logo_Padded&trim=false&partnerIdentifier=sky-uk",
        titleImageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/199ee2662b188bff7126e6fdfa333332/herotitle.png"
      },
      providerImageUrl: "https://myriad.merlin.xfinity.eu/select/logo?entityId=5224666563250046117&width=368&height=207&rule=Sky_Logo_Padded&trim=false&partnerIdentifier=sky-uk",
      action: {
        type: "GO_TO_PAGE",
        pageType: "show",
        pageId: "genericSportsUpsell"
      }
    },
    {
      type: "landscape",
      title: "Live: NFL Fantasy",
      imageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/8550f3405320a8974c82ce7686bd4503/456/257/item.jpg",

      hero: {
        mode: "standard",
        title: "Live: NFL Fantasy",
        synopsis: "Featuring the top NFL fantasy experts and analysts, this is the perfect companion to dominate your fantasy football leagues, providing key insights to build the best lineup.",
        imageUrl: " http://cdn.skyq.sky.com/qms/media/gbr/live/788e2d175809a90f03a2a603c3e6667a/1428/803/hero.jpg",
        logoUrl: "http://images.metadata.sky.com/pd-logo/skychb_4022skyspnflhd/365/205",
        titleImageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/199ee2662b14ab4f813f7edccc3971ee/herotitle.png"
      },
      providerImageUrl: "http://images.metadata.sky.com/pd-logo/skychb_4022skyspnflhd/365/205",
      action: {
        type: "GO_TO_PAGE",
        pageType: "show",
        pageId: "genericSportsUpsell"
      }
    },
    {
      type: "landscape",
      title: "Live: Total Access",
      imageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/8550f3405394b86c80c7fc2eb1f3280e/456/257/item.jpg",
      hero: {
        mode: "standard",
        title: "Live: Total Access",
        synopsis: "Get the complete picture from the gridiron with the show dedicated to the National Football League, with inside access to all 32 teams.",
        imageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/788e2d1758e6bd64be414355c2a264b4/1428/803/hero.jpg",
        logoUrl: "http://images.metadata.sky.com/pd-logo/skychb_4022skyspnflhd/365/205",
        titleImageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/199ee2662bd526beec391188e932be20/herotitle.png",
      },
      providerImageUrl: "http://images.metadata.sky.com/pd-logo/skychb_4022skyspnflhd/365/205",
      action: {
        type: "GO_TO_PAGE",
        pageType: "show",
        pageId: "genericSportsUpsell"
      }
    },
    {
      type: "landscape",
      title: "Live F1 Pro Championship",
      imageUrl: " http://cdn.skyq.sky.com/qms/media/gbr/live/8550f34053bcc1984d4b570e5785759f/456/257/item.jpg",

      hero: {
        mode: "standard",
        title: "Live F1 Pro Championship",
        synopsis: "The leading F1 gaming championship begins with the third race from the 2021 F1 Esports Series Pro Championship, held in Austria.",
        imageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/788e2d1758aeb87d50690a31d392ba4a/1428/803/hero.jpg",
        logoUrl: "http://images.metadata.sky.com/pd-logo/skychb_3835skyspf1hd/365/205",
        titleImageUrl: "http://cdn.skyq.sky.com/qms/media/gbr/live/199ee2662b5e8470af0b783a483e80e2/herotitle.png"
      },
      providerImageUrl: " http://images.metadata.sky.com/pd-logo/skychb_3835skyspf1hd/365/205",
      action: {
        type: "GO_TO_PAGE",
        pageType: "show",
        pageId: "genericSportsUpsell"
      }
    },
  ]
};

export default rail;
