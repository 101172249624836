import styled from "@emotion/styled";

export const Container = styled.div<{
    hasSubtitle: boolean;
}>`
    align-items: ${p => p.hasSubtitle ? "start" : "center"};
    display: flex;
    flex-direction: column;
    font-family: var(--llama-font-family-regular);
    gap: 10px;
    width: 100%;

    strong {
        font-family: var(--llama-font-family-medium);
        font-weight: normal;
    }
`;

export const Small = styled.div`
    font-size: var(--llama-font-size-small);
`;
