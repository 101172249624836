import { ButtonHandler } from "@ixd-group/common-services";
import { useButtons, useRxState } from "@ixd-group/react-utils";
import styled from "@emotion/styled";
import React, { useMemo, useRef, useState } from "react";
import { RailsStore } from "@ixd-group/common-stores";
import { GlassSelector } from "@ixd-group/llama-components";
import FanTile from "../../shared-components/FanTile";
import Action from "../../types/action.type";
import HistoryItem from "../../types/history-item.type";
import { FanTileData, FanMediaData } from "../../types/fan.types";
import HeroData from "../../types/hero.type";
import { Hero } from "@ixd-group/llama-components";
import SVGUniqueID from "react-svg-unique-id";

export type Props = {
  buttonHandler: ButtonHandler;
  showpageImageUrl: string;
  onSelect?: (action?: Action, indices?: HistoryItem["indices"]) => void;
  media: FanMediaData[];
  hero?: HeroData;
  firstPageWidgets?: string;
  extras?: FanMediaData[];
};

const Holder = styled.div`
  position: relative;
`;

type LayerProps = {
  x: number;
  y: number;
};

const Layer = styled.div<LayerProps>`
  position: absolute;
  top: ${(props) => props.y}px;
  left: ${(props) => props.x}px;
`;

type FanRail = {
  title: string;
  items: FanTileData[];
};

type ExtraRail = {
  title: string;
  items: FanTileData[];
};

function mediaDataToTileData(media: FanMediaData[]): FanTileData[] {
  return media.map((m) => ({
    image: m.image,
    id: m.id,
    video: m.video,
    title: m.title,
    info: m.views === "" ? "" : `${m.views} views • ${m.date}`,
    account: m.account,
    tick: m.tick,
    videoClipLabel: m.videoClipLabel ? m.videoClipLabel : null,
  }));
}

const Container = ({
  buttonHandler,
  showpageImageUrl,
  onSelect,
  media,
  firstPageWidgets,
  hero,
  extras,
}: Props) => {
  const tileData = useMemo(() => {
    return mediaDataToTileData(media);
  }, [media]);

  const extraTileData = useMemo(() => {
    if (extras) {
      return mediaDataToTileData(extras);
    } else {
      return [];
    }
  }, [extras]);

  const imageUrl = showpageImageUrl;

  const movingLayerRef = useRef<HTMLDivElement>(null);
  const upArrowRef = useRef<HTMLDivElement>(null);
  const downArrowRef = useRef<HTMLDivElement>(null);

  const [fanRails] = useState(() => {
    const store = new RailsStore<FanRail>();
    store.set([{ title: "", items: tileData }]);
    return store;
  });

  const [extraRails] = useState(() => {
    const store = new RailsStore<ExtraRail>();
    store.set([{ title: "", items: tileData }]);
    return store;
  });

  const tileIndex = useRxState(fanRails.itemIndex$(0));
  const extraTileIndex = useRxState(extraRails.itemIndex$(0));

  const [pageNumber, setPageNumber] = useState<number>(0);

  const numberOfPages = extras === undefined ? 2 : 3;

  function animateToPage(newPageNumber: number): void {
    if (newPageNumber === pageNumber) return;

    const offset = newPageNumber * -1080;
    const keyFrames = [{ transform: `translateY(${offset}px)` }];

    // Animate
    if (movingLayerRef.current) {
      movingLayerRef.current.animate(keyFrames, {
        duration: 400,
        fill: "forwards",
      });

      if (newPageNumber === 0) {
        if (upArrowRef.current) {
          upArrowRef.current.animate(
            { opacity: 0 },
            { duration: 200, fill: "forwards" }
          );
        }
        if (downArrowRef.current) {
          downArrowRef.current.animate(
            { opacity: 1 },
            { duration: 200, fill: "forwards" }
          );
        }
      }

      if (newPageNumber === numberOfPages - 1) {
        if (downArrowRef.current) {
          downArrowRef.current.animate(
            { opacity: 0 },
            { duration: 200, fill: "forwards" }
          );
        }
        if (upArrowRef.current) {
          upArrowRef.current.animate(
            { opacity: 1 },
            { duration: 200, fill: "forwards" }
          );
        }
      }
    }

    setPageNumber(newPageNumber);
  }

  function handleDown() {
    const newPageNumber = Math.min(pageNumber + 1, numberOfPages - 1);
    animateToPage(newPageNumber);
  }

  function handleUp() {
    const newPageNumber = Math.max(pageNumber - 1, 0);
    animateToPage(newPageNumber);
  }

  useButtons(["show"], buttonHandler, true, {
    DOWN: () => handleDown(),
    UP: () => handleUp(),
    LEFT: () => {
      if (pageNumber === 1) {
        fanRails.prevItem(0);
      }
      if (pageNumber === 2) {
        extraRails.prevItem(0);
      }
    },
    RIGHT: () => {
      console.log("RIGHT");
      if (pageNumber === 1) {
        fanRails.nextItem(0);
      }
      if (pageNumber === 2) {
        extraRails.nextItem(0);
      }
    },
    SELECT: () => {
      const data = fanRails.focusedItem$.get();
      if (!data.videoClipLabel || !onSelect) return;
      const action01 = {
        type: "GO_TO_PAGE",
        pageType: "video",
        pageId: data.videoClipLabel,
      } as Action;
      onSelect(action01);
    },
  });

  function tileFactory(focused: boolean) {
    return tileData.map((d) => (
      <FanTile
        info={d.info}
        image={d.image}
        account={d.account}
        title={d.title}
        key={`${focused ? "F_" : "U_"}${d.id}`}
      />
    ));
  }

  const tfFocused = () => {
    return tileFactory(true);
  };

  const tfUnfocused = () => {
    return tileFactory(false);
  };

  function extraTileFactory(focused: boolean) {
    return extraTileData.map((d) => (
      <FanTile
        info={d.info}
        image={d.image}
        account={d.account}
        title={d.title}
        key={`${focused ? "F_" : "U_"}${d.id}`}
      />
    ));
  }

  return (
    <Holder>
      <Layer x={0} y={0}>
        <img src={imageUrl} alt="background" />
      </Layer>

      {/* Background darker gradient */}
      {!firstPageWidgets ? (
        <Layer x={0} y={0}>
          <SVGUniqueID>
            <svg width={1920} height={1080}>
              <defs>
                <linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
                  <stop offset="0%" stopColor="rgba(0,0,0,0)" />
                  <stop offset="30%" stopColor="rgba(0,0,0,0.3)" />
                  <stop offset="100%" stopColor="rgba(0,0,0,0.7)" />
                </linearGradient>
              </defs>
              <rect width={1920} height={1080} fill={`url(#gradient)`} />
            </svg>
          </SVGUniqueID>
        </Layer>
      ) : null}

      {/*  Arrow Down  */}
      <Layer x={921} y={1022} ref={downArrowRef}>
        <img src={"assets/images/fanPages/arrow_down.png"} alt="arrow-down" />
      </Layer>

      {/*  Arrow Up  */}
      <Layer x={921} y={33} ref={upArrowRef} style={{ opacity: 0 }}>
        <img src={"assets/images/fanPages/arrow_up.png"} alt="arrow-down" />
      </Layer>

      <div
        style={{ top: 0, left: 0, height: `${numberOfPages * 1080}px` }}
        ref={movingLayerRef}
      >
        {/* Note: this is a bit of a hacky way to get first page widgets */}
        {firstPageWidgets ? (
          <Layer x={0} y={0}>
            <img src={firstPageWidgets} alt="widgets" />
          </Layer>
        ) : (
          <>
            {hero ? (
              <div style={{ position: "absolute", left: 0, top: 200 }}>
                <Hero
                  mode={"standard"}
                  synopsis={hero.synopsis}
                  title={hero.title}
                  logoUrl={hero.logoUrl}
                  metadata={hero.metadata}
                  style={{ border: "none" }}
                />
              </div>
            ) : null}

            <div style={{ position: "absolute", left: 0, bottom: 1080 }}>
              <img
                src={"assets/images/fanPages/playback_widgets.png"}
                alt={"mocked page interface"}
              />
            </div>
          </>
        )}

        <Layer x={100} y={400 + 1080}>
          <GlassSelector
            index={tileIndex}
            width={800}
            height={600}
            items={tfUnfocused}
            focusedItems={tfFocused}
            focused={true}
            focusedHeight={640}
            focusedExtraWidth={60}
          />
        </Layer>

        <Layer x={100} y={400 + 2160}>
          <GlassSelector
            index={extraTileIndex}
            width={800}
            height={600}
            items={() => {
              return extraTileFactory(false);
            }}
            focusedItems={() => {
              return extraTileFactory(true);
            }}
            focused={true}
            focusedHeight={640}
            focusedExtraWidth={60}
          />
        </Layer>
      </div>
    </Holder>
  );
};

export default Container;
