import { FanMediaData } from "../../../types/fan.types";

const bayernTempImage =
  "assets/images/fanPages/fc_bayern_munchen_v_fc_salzburg_round_of_sixteen_leg_two_-_uefa_champions_league.webp";

export const mediaData: {
  Dune: FanMediaData[];
  Bayern: FanMediaData[];
  Greys: FanMediaData[];
} = {
  Dune: [
    {
      id: "Dune01",
      views: "1,300,110",
      date: "24 Oct 2021",
      title: "Behind The Making Of Dune",
      account: "TheThings",
      tick: true,
      video: "https://youtu.be/UvtiiuyO-gU",
      image: "assets/images/fanPages/tiles/MakingOfDune.jpg",
      videoClipLabel: "makingOfDune",
    },
    {
      views: "980,453",
      title:
        "DUNE BREAKDOWN & Analysis! Details You Missed & Ending Explained!",
      account: " New Rockstars",
      tick: true,
      date: "25 Oct 2021",
      id: "Dune02",
      video: "https://youtu.be/1kE9LOhEjMQ",
      image: "assets/images/fanPages/tiles/dune_vid_02.jpg",
      videoClipLabel: "duneBreakdown",
    },
    {
      id: "Dune03",
      views: "1,818,388",
      title: "The Politics of Dune Explained In FIVE Minutes",
      account: "Quinn's Ideas",
      tick: true,
      date: "24 Oct 2021",
      image: "assets/images/fanPages/tiles/politics_on_dune.jpg",
      video: "https://youtu.be/Cli2PU03u3A",
      videoClipLabel: "politicsInDune",
    },
    {
      id: "Dune04",
      video: "https://youtu.be/D86Mqk8TVqE",
      views: "1,393,364",
      title:
        "Oscar Isaac Bonded with Timothée Chalamet on the Set of Dune | The Tonight Show	The Tonight Show Starring Jimmy Fallon",
      date: "10/16/2021",
      tick: true,
      account: "The Tonight Show",
      image: "assets/images/fanPages/tiles/MakingOfDune.jpg",
    },
    {
      id: "Dune05",
      video: "https://youtu.be/GoAA0sYkLI0",
      views: "3,582,211",
      title: "Dune Director Denis Villeneuve Breaks Down the Gom Jabbar Scene",
      account: "Vanity Fair",
      date: "22 Oct 2021",
      tick: true,
      image: "assets/images/fanPages/tiles/MakingOfDune.jpg",
    },
  ],
  Bayern: [
    {
      id: "Munch01",
      video: "https://youtu.be/M0Y35A-GHaQ",
      views: "469,284",
      title: "The reactions to the 10th championship title in a row",
      account: "FC Bayern Munich",
      date: "23 Apr 2022",
      tick: true,
      image: "assets/images/fanPages/tiles/bayern02.jpg",
      videoClipLabel: "bayernClip01",
    },
    {
      id: "Munch02",
      video: "https://youtu.be/jz_78Y7l6qQ",
      views: "121,411",
      title: "Thomas Müller's 25 most special goals for FC Bayern",
      account: "FC Bayern",
      date: "3 May 2022",
      tick: true,
      image: "assets/images/fanPages/tiles/bayern03.jpg",
      videoClipLabel: "bayernClip02",
    },
    {
      id: "Munch03",
      video: "https://youtu.be/jci-sxWTBcU",
      views: "92,629",
      title: "Die Meister beim öffentlichen Training",
      account: "FC Bayern Munich",
      date: "26 Apr 2022",
      tick: true,
      image: bayernTempImage,
      videoClipLabel: "bayernClip03",
    },
    {
      id: "Munch04",
      video: "https://youtu.be/vAETp76H1Jk",
      views: "2,368,854",
      title:
        "Copy the Penalty Challenge ft. Thomas Müller, Luva de Pedreiro, Ali471 & more!",
      account: "FC Bayern Munich",
      date: "20 Mar 2022",
      tick: true,
      image: "assets/images/fanPages/tiles/bayern04.jpeg",
      videoClipLabel: "bayernClip04",
    },
  ],
  Greys: [
    {
      id: "Greys01",
      video: "https://youtu.be/I_MMv4Yxsfw",
      views: "78,185",
      title: "Season 17 Complete Recap	TV Glimpse",
      date: "25 Apr 2021",
      tick: true,
      image: "assets/images/fanPages/tiles/greys04.webp",
      account: "TV Glimpse",
      videoClipLabel: "greysClip01",
    },
    {
      id: "Greys02",
      video: "https://youtu.be/10hEW6xjgyk",
      views: "575,908",
      title: "Ellen Pompeo on Grey’s Anatomy Fan Theories",
      date: "8 Sep 2021",
      tick: true,
      image: "assets/images/fanPages/tiles/greys03.jpeg",
      account: "Jimmy Kimmel Live",
      videoClipLabel: "greysClip02",
    },
    {
      id: "Greys03",
      video: "https://youtu.be/BStD57jqm7A",
      views: "636,936",
      title: "Ellen Pompeo on the future of Grey's Anatomy",
      date: "28 Feb 2021",
      tick: true,
      image: "assets/images/fanPages/tiles/greys02.jpeg",
      account: "CBS Sunday Morning",
      videoClipLabel: "greysClip03",
    },
    {
      id: "Greys04",
      video: "https://youtu.be/-FyRzgJFeLE",
      views: "29,614,473",
      title: "Real Doctor Reacts to GREY'S ANATOMY",
      date: "8 Apr 2018",
      tick: true,
      image: "assets/images/fanPages/tiles/greys01.jpeg",
      account: "Doctor Mike",
      videoClipLabel: "greysClip04",
    },
  ],
};

export function findMedia(
  label: string
): { video: string; image: string } | null {
  const result = mediaData.Dune.find((d) => d.videoClipLabel === label);
  if (result) {
    const { video, image } = result;
    return { video, image };
  }
  return null;
}

/*



    
  
    
    	
    Bayern Munich	https://youtu.be/vAETp76H1Jk	2,368,854	Kimmich vs. Gnabry | Copy the Penalty Challenge ft. Thomas Müller, Luva de Pedreiro, Ali471 & more!	FC Bayern Munich	3/20/2022
    Bayern Munich	https://youtu.be/h5svcTNaOCg	141,422	Always a Bavarian: Thomas Müller extends his contract until 2024	FC Bayern Munich	5/4/2022
    Grey's Anatomy	https://youtu.be/jZzc8bnZTk0	26,299	Jesse Williams Teases a ‘Grey’s Anatomy’ Return | WWHL	Watch What Happens Live with Andy Cohen	5/10/2022
Grey's Anatomy	https://youtu.be/I_MMv4Yxsfw	78,185	Grey's Anatomy | Season 17 Complete Recap	TV Glimpse	9/25/2021
Grey's Anatomy	https://youtu.be/10hEW6xjgyk	575,908	Ellen Pompeo on Grey’s Anatomy Fan Theories, Shooting Coma Scenes & Jarring Her Own Tomatoes	Jimmy Kimmel Live	10/8/2021
Grey's Anatomy	https://youtu.be/BStD57jqm7A	636,936	Ellen Pompeo on the future of "Grey's Anatomy"	CBS Sunday Morning	2/28/2021
Grey's Anatomy	https://youtu.be/-FyRzgJFeLE	29,614,473	Real Doctor Reacts to GREY'S ANATOMY | Medical Drama Review | Doctor Mike	Doctor Mike	4/8/2018


{
    image: "assets/images/fanPages/tiles/MakingOfDune.jpg",
        id: "T01",
    video: "https://www.youtube.com/watch?v=UvtiiuyO-gU",
    title: "Behind the Making of Dune",
    info: "1,300,037 views • 24 Oct 2021",
    account: "TheThings ",
    tick: true,


 */
