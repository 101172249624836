import type Rail from "../../../types/rail.type";

const rail: Rail = {
    template: "tile-cover",
    title: "Sky Cinema",
    items: [
        {
            type: "cover",
            imageUrl: "https://images.metadata.sky.com/pd-image/f01891f0-ebbc-44fd-9999-790dbb1985bb/cover/{height}",
            hero: {
                backgroundImageUrl: "assets/images/brands/skycinema/background.jpg",
                mode: "standard",
                imageUrl: "https://images.metadata.sky.com/pd-image/f01891f0-ebbc-44fd-9999-790dbb1985bb/background/{width}",
                logoUrl: "assets/images/provider/sky_cinema.png",
                title: "Inception",
                synopsis: "Leonardo DiCaprio is a master of sub-conscious espionage in Christopher Nolan's blockbuster.",
                metadata: "2010    2h 22m"
            }
        },
        {
            type: "cover",
            imageUrl: "https://images.metadata.sky.com/pd-image/4e0a254f-13fc-4e39-9f47-166dd10f3c79/cover/{height}",
            hero: {
                backgroundImageUrl: "assets/images/brands/skycinema/background.jpg",
                mode: "standard",
                imageUrl: "https://images.metadata.sky.com/pd-image/4e0a254f-13fc-4e39-9f47-166dd10f3c79/background/{width}",
                logoUrl: "assets/images/provider/sky_cinema.png",
                title: "The Witches",
                synopsis: "Anne Hathaway and Octavia Spencer star in this spooky tale of orphan versus witch, adapted from Roald Dahl's best-selling book.",
                metadata: "2020    1h 46m"
            }
        },
        {
            type: "cover",
            imageUrl: "https://images.metadata.sky.com/pd-image/7b3bb5ec-853d-4a1a-b6f6-aac6de3d5330/cover/{height}",
            hero: {
                backgroundImageUrl: "assets/images/brands/skycinema/background.jpg",
                mode: "standard",
                imageUrl: "https://images.metadata.sky.com/pd-image/7b3bb5ec-853d-4a1a-b6f6-aac6de3d5330/background/{width}",
                logoUrl: "assets/images/provider/sky_cinema.png",
                title: "The Hunger Games",
                synopsis: "A group of teens are forced to use their ingenuity in a thrilling fight for survival. Jennifer Lawrence stars.",
                metadata: "2012    2h 18m"
            }
        },
        {
            type: "cover",
            imageUrl: "https://images.metadata.sky.com/pd-image/00abb689-5438-45d3-9508-f4599767149c/cover/{height}",
            hero: {
                backgroundImageUrl: "assets/images/brands/skycinema/background.jpg",
                mode: "standard",
                imageUrl: "https://images.metadata.sky.com/pd-image/00abb689-5438-45d3-9508-f4599767149c/background/{width}",
                logoUrl: "assets/images/provider/sky_cinema.png",
                title: "Spider-Man: Homecoming",
                synopsis: "Tom Holland is Peter Parker - an average kid with a web-slinging alter ego out to protect New York from a menacing new villain.",
                metadata: "2017    2h 9m"
            }
        },
        {
            type: "cover",
            imageUrl: "https://images.metadata.sky.com/pd-image/0a010558-f757-4fc1-9037-87c94f9b8532/cover/{height}",
            hero: {
                backgroundImageUrl: "assets/images/brands/skycinema/background.jpg",
                mode: "standard",
                imageUrl: "https://images.metadata.sky.com/pd-image/0a010558-f757-4fc1-9037-87c94f9b8532/background/{width}",
                logoUrl: "assets/images/provider/sky_cinema.png",
                title: "Paddington",
                synopsis: "A young Peruvian bear travels to London in search of a home. Delightful big-screen adaptation of the beloved books starring Ben Whishaw and Hugh Bonneville.",
                metadata: "2015    1h 31m"
            }
        },
        {
            type: "cover",
            imageUrl: "https://images.metadata.sky.com/pd-image/91058925-505f-4e3b-9e70-1f77e2d4a619/cover/{height}",
            hero: {
                backgroundImageUrl: "assets/images/brands/skycinema/background.jpg",
                mode: "standard",
                imageUrl: "https://images.metadata.sky.com/pd-image/91058925-505f-4e3b-9e70-1f77e2d4a619/background/{width}",
                logoUrl: "assets/images/provider/sky_cinema.png",
                title: "Limitless",
                synopsis: "Struggling writer Bradley Cooper takes an IQ-enhancing pill that propels him to untold success, but troubling side effects soon emerge.",
                metadata: "2011    1h 41m"
            }
        },
        {
            type: "cover",
            imageUrl: "https://images.metadata.sky.com/pd-image/7a52e8b7-5491-48e4-a6c8-cab5e1ade636/cover/{height}",
            hero: {
                backgroundImageUrl: "assets/images/brands/skycinema/background.jpg",
                mode: "standard",
                imageUrl: "https://images.metadata.sky.com/pd-image/7a52e8b7-5491-48e4-a6c8-cab5e1ade636/background/{width}",
                logoUrl: "assets/images/provider/sky_cinema.png",
                title: "La La Land",
                synopsis: "Oscar-winning musical starring Emma Stone as a wannabe actress who falls for frustrated pianist Ryan Gosling.",
                metadata: "2016    2h 8m"
            }
        }
    ]
};

export default rail;
