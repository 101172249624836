import type Rail from "../../../types/rail.type";
import { SwitcherTile } from "../../../types/tile.type";

const Bayern: SwitcherTile = {
  type: "switcher",
  tag: "Top pick for you",
  title: "Bayern Munich v Stuttgart",
  imageUrl:
    "http://myriad.merlin.xfinity.eu/select/image?entityId=5551312309082791112&companyId=6737930311996437105&width=600&height=800&rule=Sky_Cover&partnerIdentifier=sky-uk",
  providerImageUrl: "assets/images/provider/sky_sports_football.png",

  action: {
    type: "GO_TO_PAGE",
    pageType: "fan",
    pageId: "bayernFanPage",
  },

  hero: {
    mode: "standard",
    logoUrl:
      "http://myriad.merlin.xfinity.eu/select/logo?entityId=6737930311996437105&width=368&height=207&rule=Sky_Logo_Padded&trim=false&partnerIdentifier=sky-uk",
    title: "Bayern Munich v Stuttgart",
    synopsis:
      "Stuttgart pay a visit to the Allianz Arena, home of the German champions Bayern Munich. The visitors face an uphill battle to cling onto their Bundesliga status.",
    metadata: "2022 ",
    imageUrl:
      "http://myriad.merlin.xfinity.eu/select/image?entityId=5551312309082791112&companyId=6737930311996437105&width=1920&height=1080&rule=Sky_Background&partnerIdentifier=sky-uk",
    titleImageUrl: "",
  },
};

const Dune: SwitcherTile = {
  type: "switcher",
  tag: "Top pick for you",
  title: "Dune",
  imageUrl:
    "http://myriad.merlin.xfinity.eu/select/image?entityId=6019943976673791112&companyId=9107617000750087105&width=600&height=800&rule=Sky_Cover&partnerIdentifier=sky-uk",

  hero: {
    mode: "standard",
    logoUrl:
      "http://myriad.merlin.xfinity.eu/select/logo?entityId=9107617000750087105&width=368&height=207&rule=Sky_Logo_Padded&trim=false&partnerIdentifier=sky-uk",
    title: "Dune",
    synopsis:
      "Timothée Chalamet stars as the son of a noble family who must protect the galaxy's most valuable asset. Oscar-winning sci-fi with Zendaya and Jason Momoa. (2021)(155 mins)",
    metadata: "2021 ",
    imageUrl:
      "http://cdn.skyq.sky.com/qms/media/gbr/live/788e2d17582128ac68b10b9a0841c4c7/hero.jpg",
    titleImageUrl:
      "http://cdn.skyq.sky.com/qms/media/gbr/live/199ee2662bc904e7713a3aa86df0f266/herotitle.png",
  },
  providerImageUrl: "assets/images/provider/sky_cinema.png",
  action: {
    type: "GO_TO_PAGE",
    pageType: "fan",
    pageId: "duneFanPage",
  },
};

const GreysAnatomy: SwitcherTile = {
  type: "switcher",
  tag: "Top pick for you",
  title: "Grey's Anatomy",
  imageUrl:
    "http://myriad.merlin.xfinity.eu/select/image?entityId=7305834959116634112&companyId=7872603476396289105&width=1920&height=1080&rule=Sky_Tile&partnerIdentifier=sky-uk",
  hero: {
    mode: "standard",
    title: "Grey's Anatomy",
    synopsis:
      "Medical residents try to cope with the demands of life both inside and outside the hectic hospital.",
    logoUrl:
      "http://myriad.merlin.xfinity.eu/select/logo?entityId=7872603476396289105&width=368&height=207&rule=Sky_Logo_Padded&trim=false&partnerIdentifier=sky-uk",
    metadata: "2021 ",
    imageUrl:
      "http://myriad.merlin.xfinity.eu/select/image?entityId=7305834959116634112&companyId=7872603476396289105&width=1920&height=1080&rule=Sky_Background&partnerIdentifier=sky-uk",
    titleImageUrl:
      "http://cdn.skyq.sky.com/qms/media/gbr/live/199ee2662bf4633b547e6c6ce8a183b4/herotitle.png",
  },
  providerImageUrl: "assets/images/provider/sky_witness.png",

  action: {
    type: "GO_TO_PAGE",
    pageType: "fan",
    pageId: "greysAnatomyFanPage",
  },
};

const Romesh: SwitcherTile = {
  type: "switcher",
  tag: "Top pick for you",
  title: "Rob & Romesh Vs",
  imageUrl: "assets/images/content/rob_and_romesh_vs/tile.png",
  providerImageUrl: "assets/images/provider/sky_one.png",
  hero: {
    mode: "standard",
    titleImageUrl: "assets/images/content/rob_and_romesh_vs/title.png",
    imageUrl:
      "https://images.metadata.sky.com/pd-image/9ee77026-ec3c-4db1-a3a1-5e17b14d4c52/background/{width}",
    logoUrl: "assets/images/provider/sky_one.png",
    title: "Rob & Romesh Vs",
    synopsis:
      "Funny men Rob Beckett and Romesh Ranganathan are back to interview more celebrities from the worlds of sport, entertainment, fashion, and more.",
    metadata: "2019    3 series",
  },
};

const UnitedWay: SwitcherTile = {
  type: "switcher",
  tag: "Top pick for you",
  title: "The United Way",
  imageUrl:
    "https://myriad.merlin.xfinity.eu/select/image?entityId=4895087523664482112&width=464&height=261&rule=Sky_Tile&partnerIdentifier=sky-uk",

  hero: {
    mode: "standard",
    title: "The United Way",
    synopsis:
      "The story of Manchester United Football Club featuring insight from David Beckham and more.",
    imageUrl:
      "https://myriad.merlin.xfinity.eu/select/image?entityId=4895087523664482112&width=1920&height=1080&rule=Sky_Background&partnerIdentifier=sky-uk",
    logoUrl:
      "https://myriad.merlin.xfinity.eu/select/logo?entityId=8656828586641685105&width=368&height=207&rule=Sky_Logo_Padded&trim=false&partnerIdentifier=sky-uk",
    // titleImageUrl:
  },
  providerImageUrl:
    "https://myriad.merlin.xfinity.eu/select/logo?entityId=8656828586641685105&width=368&height=207&rule=Sky_Logo_Padded&trim=false&partnerIdentifier=sky-uk",
  action: {
    type: "GO_TO_PAGE",
    pageType: "show",
    pageId: "documentTheUnitedWay",
  },
};

const rail: Rail = {
  template: "tile-switcher",
  items: [
    Dune,
    Bayern,
    GreysAnatomy,
    Romesh,
    {
      type: "switcher",
      tag: "Top pick for you",
      title: "The Nevers",
      imageUrl: "assets/images/content/the_nevers/tile.png",
      providerImageUrl: "assets/images/provider/sky_atlantic.png",
      hero: {
        mode: "standard",
        titleImageUrl: "assets/images/content/the_nevers/title.png",
        imageUrl: "assets/images/content/the_nevers/hero.jpg",
        logoUrl: "assets/images/provider/sky_atlantic.png",
        title: "The Nevers",
        synopsis:
          "Victorian women find themselves with unusual abilities, relentless enemies and a mission that may change the world.",
      },
    },
    UnitedWay,
    {
      type: "switcher",
      tag: "Top pick for you",
      title: "The Dig",
      imageUrl: "assets/images/content/the_dig/tile.png",
      providerImageUrl: "assets/images/provider/netflix.png",
      hero: {
        mode: "standard",
        titleImageUrl: "assets/images/content/the_dig/title.png",
        imageUrl: "assets/images/content/the_dig/hero.jpg",
        logoUrl: "assets/images/provider/netflix.png",
        title: "The Dig",
        synopsis:
          "On the eve of World War II, a British widow hires a self-taught archaeologist to dig up mysterious formations on her land, leading to a staggering find.",
      },
    },
    {
      type: "switcher",
      tag: "Top pick for you",
      title: "Outer Banks",
      imageUrl: "assets/images/content/outer_banks/tile.png",
      providerImageUrl: "assets/images/provider/netflix.png",
      hero: {
        mode: "standard",
        titleImageUrl: "assets/images/content/outer_banks/title.png",
        imageUrl: "assets/images/content/outer_banks/hero.jpg",
        logoUrl: "assets/images/provider/netflix.png",
        title: "Outer Banks",
        synopsis:
          "On an island of haves and have-nots, teen John B enlists his three best friends to hunt for a legendary treasure linked to his father's disappearance.",
      },
    },
  ],
};

export default rail;
