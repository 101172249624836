import type { CategoryPage } from "../../../types/page.type";

import topPicks from "./topPicks";
import kidsClub from "./kidsClub";
import readAsUWatch from "./readAsUWatch";
import favourites from "./favourites";
import under5 from "./under5";

export default {
    id: "skyKids",
    rails: [
        topPicks,
        favourites,
        kidsClub,
        under5,
        readAsUWatch,
    ],
    title: "Kids",
    type: "category"
} as CategoryPage;
