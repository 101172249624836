import React from "react";
import { css, Global } from "@emotion/react";

const GlobalStyles: React.FC = () => (
    <Global
        styles={css`
            @font-face {
                font-family: "Sky Text Medium";
                src: url("fonts/SkyText_Md.ttf");
            }
        
            @font-face {
                font-family: "Sky Text Regular";
                src: url("fonts/SkyText_Rg.ttf");
            }
        
            @font-face {
                font-family: "Sky Text Light";
                src: url("fonts/SkyText_Lt.ttf");
            }

            :root {
                --llama-color-white: #f1f1f1;
                --llama-color-tile-pale: rgba(255, 255, 255, 0.08);
            
                --llama-font-family-regular: "Sky Text Regular";
                --llama-font-family-medium: "Sky Text Medium";
                --llama-font-family-light: "Sky Text Light";
            
                --llama-font-size-small: 28px;
                --llama-font-size-medium: 32px;
                --llama-font-size-large: 40px;
                --llama-font-size-huge: 50px;
                --llama-font-size-humungous: 100px;
                --llama-font-size-ginormous: 112px;
            
                --llama-tile-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.12);
                --llama-text-shadow: 1px 2px 0px rgba(0, 0, 0, 0.8);
            }

            *,
            *::before,
            *::after {
                box-sizing: inherit;
            }

            body {
                background-color: #000000;
                box-sizing: border-box;
                margin: 0;
                overflow: hidden;
                -webkit-font-smoothing: antialiased;
            }
        `}
    />
)

export default GlobalStyles;
